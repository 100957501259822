import { useMantineColorScheme } from "@mantine/core";
import React, {useState} from "react";
import MainBtn from "../buttons/MainBtn";
import {AnimatePresence, motion} from "framer-motion";
import man from '../../../assets/images/shape-dark.svg'
import manDark from '../../../assets/images/shape.svg'
import axios from "axios";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {useParams} from "react-router-dom";

type RingModalProps = {
    setShowModal: (value: boolean) => void,
}

const RingModal: React.FC<RingModalProps> = ({setShowModal}) => {
    const { colorScheme } = useMantineColorScheme();
    const setShowModalHandler = () => {
        setShowModal(false);
    }

    const [success, setSuccess] = useState(false);
    const {restaurantInfo} = useAppSelector(state => state.main)

    const params = useParams();

    const sendRing = async (type: string) => {
      if(restaurantInfo) {
        try {
          const result = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${restaurantInfo._id}/officiant_calls`, {
            table: params.table,
            type: type
          })
          if(result.status === 201) {
            setSuccess(true);
          }
        } catch (e: any) {

        }
      }
    }

    return (
        <AnimatePresence mode={"wait"}>
            {
                success ?
                    <motion.div
                        initial={{y: '100%', opacity: 0}}
                        animate={{y: '0', opacity: 1}}
                        transition={{duration: 0.3}}
                        exit={{y: '100%', opacity: 0}}
                        className="ring-modal__content">

                        <img className={'success-img'} src={colorScheme === 'dark' ? man : manDark} alt=""/>
                        <p className="success-title">Ожидайте пожалуйста!</p>
                        <p className="success-text">Мы уже передали информацию вашему официанту</p>
                        <div
                            className={`modal-close ${colorScheme === "dark" ? "dark" : ""}`}
                            onClick={setShowModalHandler}
                        >
                            <div className="modal-close__inner">
                                <div className="modal-close__line modal-close__line--first"></div>
                                <div className="modal-close__line modal-close__line--second"></div>
                            </div>
                        </div>
                    </motion.div>
                    :
                    <motion.div
                         initial={{y: '100%', opacity: 0}}
                            animate={{y: '0', opacity: 1}}
                            transition={{duration: 0.3}}
                            exit={{y: '100%', opacity: 0}}
                        className="ring-modal__content">

                        <div className="ring-modal__btn-wrapper">
                            <MainBtn type="empty" text="Попросить счет" onClick={() => sendRing('bill')}/>
                        </div>
                        <div className="ring-modal__btn-wrapper">
                            <MainBtn text="Позвать официанта" onClick={() => sendRing('call')}/>
                        </div>
                        <div
                            className={`modal-close ${colorScheme === "dark" ? "dark" : ""}`}
                            onClick={setShowModalHandler}
                        >
                            <div className="modal-close__inner">
                                <div className="modal-close__line modal-close__line--first"></div>
                                <div className="modal-close__line modal-close__line--second"></div>
                            </div>
                        </div>
                    </motion.div>

            }
        </AnimatePresence>


    )
}

export default RingModal;
