import React from "react";

type MainBtnProps = {
    type?: string
    text: string,
    onClick?: () => void
}

const MainBtn: React.FC<MainBtnProps> = ({type, text, onClick}) => {
    return (
        <button
            className={`main-btn ${type && type === "empty" && "main-btn--empty"}`}
            onClick={onClick}
        >{text}</button>
    )
}

export default MainBtn