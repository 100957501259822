import { useMantineColorScheme } from "@mantine/core";
import React from "react";
import minusIcon from "../../../assets/images/minus.svg";
import minusWhiteIcon from "../../../assets/images/minus_white.svg";
import plusIcon from "../../../assets/images/plus.svg";
import plusWhiteIcon from "../../../assets/images/plus_white.svg";
import minusDisableIcon from "../../../assets/images/minus_disable.svg";
import minusDisableWhiteIcon from "../../../assets/images/minus_disable_white.svg";
import minusForAddedProductIcon from "../../../assets/images/minus_for_added_product.svg";
import minusWhiteForAddedProductIcon from "../../../assets/images/minus_white_for_added_product.svg";
import plusForAddedProductIcon from "../../../assets/images/plus_for_added_product.svg";
import plusWhiteForAddedProductIcon from "../../../assets/images/plus_white_for_added_product.svg";

type DishCountProps = {
    increaseHandler: () => void,
    decreaseHandler: () => void,
    cardCount: number,
    type?: string,
    text?: string
}

const DishCount: React.FC<DishCountProps> = ({increaseHandler, decreaseHandler, cardCount, type, text}) => {
    const { colorScheme } = useMantineColorScheme();

    return (
        <div className="dish-count" style={{width: text ? '30%' : ''}}>
            <div
                className={`dish-count__change dish-count__change--decrease ${colorScheme === "dark" && "dark"} ${cardCount === 1 ? "disable" : ""}`}
                onClick={decreaseHandler}
                style={{width: text ? '70px' : ''}}

            >
                {type && type === "product" && cardCount === 0 ?
                    <img src={colorScheme === "dark" ? minusDisableWhiteIcon : minusDisableIcon} alt="" /> : ""
                }
                {!type ?
                    <img src={colorScheme === "dark" ? minusWhiteIcon : minusIcon} alt="" /> : ""
                }
                {type && type === "addedProduct" ?
                    <img src={colorScheme === "dark" ? minusWhiteForAddedProductIcon : minusForAddedProductIcon} alt="" /> : ""
                }
            </div>
            <div className={`dish-count__value ${colorScheme === "dark" && "dark"}`} style={{width: text ? '100%' : '', fontSize: text ? '18px' : ''}}>{text ? <><span>{text}</span> {cardCount}</>  : cardCount}</div>
            <div
                className={`dish-count__change dish-count__change--increase ${colorScheme === "dark" && "dark"}`}
                onClick={increaseHandler}
                style={{width: text ? '70px' : ''}}
            >
                {type && type === "addedProduct" ?
                    <img src={colorScheme === "dark" ? plusWhiteForAddedProductIcon : plusForAddedProductIcon} alt="" /> :
                    <img src={colorScheme === "dark" ? plusWhiteIcon : plusIcon} alt="" />
                }
            </div>
        </div>
    )
}

export default DishCount;
