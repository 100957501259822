import React, {useEffect, useRef, useState} from "react";
import { setActiveCategory } from "../../../redux/slices/menu";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {useMantineColorScheme} from "@mantine/core";
import 'swiper/css';
import {Swiper, SwiperSlide} from "swiper/react";

const MenuTabs: React.FC = () => {
    const dispatch = useAppDispatch();
    const categories = useAppSelector(state => state.menu.categories);
    const activeCategory = useAppSelector(state => state.menu.activeCategory);
    const {popularProductsId, products} = useAppSelector(state => state.menu)

    const { colorScheme } = useMantineColorScheme();

    const onMenuTabsItemClickHandler = (index: number) => {
      if(categories) {
        dispatch(setActiveCategory(categories[index]));
      }
    }


    const ref: any = useRef();
    const Visible = function (target: any, index: number) {
        const targetPosition = {
                top: window.pageYOffset + target.getBoundingClientRect().top,
                left: window.pageXOffset + target.getBoundingClientRect().left,
                right: window.pageXOffset + target.getBoundingClientRect().right,
                bottom: window.pageYOffset + target.getBoundingClientRect().bottom
            },
            windowPosition = {
                top: window.pageYOffset,
                left: window.pageXOffset,
                right: window.pageXOffset + document.documentElement.clientWidth,
                bottom: window.pageYOffset + document.documentElement.clientHeight
            };
        if (targetPosition.left < windowPosition.left + 18){
            if(index === 0) {
                ref.current.scrollLeft += targetPosition.left - (windowPosition.left + 25);
            } else {
                ref.current.scrollLeft -= target.getBoundingClientRect().width + 50;
            }
        } else if (targetPosition.right > windowPosition.right - 20) {
            if(categories?.length && index === categories?.length - 1) {
                ref.current.scrollLeft += targetPosition.right + (windowPosition.right + 25);
            } else {
                ref.current.scrollLeft += target.getBoundingClientRect().width + 30;
            }
        }
    };

    const [popularActive, setPopularActive] = useState(false);

    useEffect(() => {
      if(categories) {
        popularProductsId?.map(item => {
          if(products?.filter(product => product._id === item) && products?.filter(product => product._id === item).length > 0) {
            if(!activeCategory?.linked){
              dispatch(setActiveCategory(categories[0]));
            }
            setPopularActive(true)
          } else {
            setPopularActive(false)
            dispatch(setActiveCategory(categories[1]));
          }
        })
      }
    }, [popularProductsId, products])

    return (
        <div className="menu-tabs" ref={ref}>
                {
                    categories?.map((item, index) => {
                      if(item.title !== 'Популярные' || popularActive) {
                         return(
                           <div
                          key={`${item}_${index}`}
                          className={`menu-tabs__item ${item.title === activeCategory?.title && "active"} ${colorScheme === "dark" ? "dark" : ''}`}
                          onClick={(e) => {
                            Visible(e.target, index)
                            onMenuTabsItemClickHandler(index);
                          }}
                        >{item.title}</div>)
                      }
                    }
                    )
                }
        </div>
    )
}

export default MenuTabs;
