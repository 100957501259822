import { useMantineColorScheme } from "@mantine/core";
import React, {useEffect, useState} from "react";
import DishCount from "./DishCount";
import {useDispatch} from "react-redux";
import {addCountProductCart, addToCart, removeCountFromCart} from "../../../redux/slices/menu";
import {IMenuItem} from "../../../types";
import {useAppSelector} from "../../../hooks/useAppSelector";

type DishButtonsProps = {
    price: number,
    type?: string,
    unit: IMenuItem,
    onClick?: any
}

const DishButtons: React.FC<DishButtonsProps> = ({price, type, unit, onClick}) => {
    const { colorScheme } = useMantineColorScheme();
    const {cartItems} = useAppSelector(state => state.menu);
    const [cardCount, setCardCount] = useState(0);

    useEffect(() => {
      if(cartItems.length > 0) {
        let count = 0; 
        if(type === 'addedProduct') {
          cartItems.filter(item => item._id === unit._id).map(item => {
            if(item.activeOption && unit.activeOption) {
              if(item.activeOption._id === unit.activeOption?._id) {
                if(item.activeAdditionals && unit.activeAdditionals) {
                  if(JSON.stringify(item.activeAdditionals) === JSON.stringify(unit.activeAdditionals)) {
                    setCardCount(item.count)
                  }
                } else {
                  setCardCount(item.count)
                }
              }
            } else {
              setCardCount(item.count)
            }
          })
        } else {
          cartItems.filter(item => item._id === unit._id).map(item => {
            count+=item.count;
          })
          setCardCount(count)
        }
      } else {
        setCardCount(0)
      }
      if(!cartItems.map(item => item._id).includes(unit._id)) {
        setCardCount(0)
      }
    }, [cartItems])

    const dispatch = useDispatch();

    const decreaseHandler = () => {
        setCardCount(cardCount - 1);
        if(type === 'addedProduct') {
          dispatch(removeCountFromCart({...unit, from: 'addedProduct', count: cardCount, price: price}))
        } else {
          const unitPrice = cartItems.find(item => item._id === unit._id)?.price
          const unitCount = cartItems.find(item => item._id === unit._id)?.count
          dispatch(removeCountFromCart({...unit, from: 'menu', count: unitCount, price: unitCount && unitPrice ? unitPrice : price * cardCount}))
        }
    }

    const increaseHandler = () => {
      if(type === 'addedProduct') {
        dispatch(addCountProductCart({...unit}))
      } else {
        dispatch(addToCart({...unit, count: 1}))
        setCardCount(cardCount + 1);
      }
    }

    return (
        <div className="dish-buttons">
            {
                cardCount === 0 ?

                    <div
                        className={`dish-buttons__initial ${colorScheme === "dark" && "dark"}`}
                        onClick={() => onClick && ((unit.additionallItems && unit.additionallItems.length > 0) || (unit.optionsItems && unit.optionsItems.length > 0)) ? onClick() : increaseHandler()}
                    >
                      {unit.oldPrice && <p className={'old-price'}>{unit.oldPrice} ₽</p>}
                        {price} ₽</div>
                    :
                    <DishCount
                        increaseHandler={() => onClick && ((unit.additionallItems && unit.additionallItems.length > 0) || (unit.optionsItems && unit.optionsItems.length > 0)) ? onClick() : increaseHandler()}
                        decreaseHandler={decreaseHandler}
                        cardCount={cardCount}
                        type={type}
                    />
            }
        </div>
    )
}

export default DishButtons;
