import { useMantineColorScheme } from "@mantine/core";
import React, {useEffect, useRef, useState} from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Modal from "../common/modals/modal";
import RingModal from "../common/modals/RingModal";
import Ring from "../common/Ring";
import {setShowInfoModal, setShowModal, setShowProductModal, setShowWifiModal} from "../../redux/slices/main";
import ProductModal from "../common/modals/ProductModal";
import WifiModal from "../common/modals/WifiModal";
import InfoModal from "../common/modals/InfoModal";

type MainLayoutProps = {
    children: React.ReactNode,
    type?: string
}

const MainLayout: React.FC<MainLayoutProps> = ({children, type}) => {
    const dispatch = useAppDispatch();
    const { colorScheme } = useMantineColorScheme();
    const showModal = useAppSelector(state => state.main.showModal);
    const showProductModal = useAppSelector(state => state.main.showProductModal);
    const showWifiModal = useAppSelector(state => state.main.showWifiModal);
    const showInfoModal = useAppSelector(state => state.main.showInfoModal);
    const headerShow = useAppSelector(state => state.main.headerShow);
    const [active, setActive] = React.useState(false);
    const showSearch = useAppSelector(state => state.main.showSearch);
    const scroll = useAppSelector(state => state.main.scroll);

    const showRingModalHandler = (value: boolean) => {
        dispatch(setShowModal(value));
    }

    useEffect(() => {
      document.documentElement.scrollTop = scroll
    }, [showProductModal])

    const showProducModalHandler = (value: boolean) => {
      dispatch(setShowProductModal(value));
    }

    const showWifiModalHandler = (value: boolean) => {
      dispatch(setShowWifiModal(value));
    }

    const showInfoModalHandler = (value: boolean) => {
      dispatch(setShowInfoModal(value));
    }

    return (
        <div className={`main-layout ${colorScheme === "dark" && "dark"}`}>
            <div className={`main-layout__inner ${showModal || showProductModal || showSearch || showWifiModal || showInfoModal ? "disable" : ""}`}>
                {headerShow &&
                    <Header type={type} />
                }
                {children}
                {type && type === "home" ? <Footer /> : ""}
                <Ring />
            </div>


            <Modal
                showModal={showModal}
                setShowModal={showRingModalHandler}
                styleModal="ring-modal"
                active={active}
                setActive={setActive}
            >
                <RingModal setShowModal={showRingModalHandler} />
            </Modal>
            <Modal
              showModal={showWifiModal}
              setShowModal={showWifiModalHandler}
              styleModal="ring-modal"
              active={active}
              setActive={setActive}
            >
              <WifiModal setShowModal={showWifiModalHandler} />
            </Modal>
            <Modal
              showModal={showInfoModal}
              setShowModal={showInfoModalHandler}
              styleModal="ring-modal"
              active={active}
              setActive={setActive}
            >
              <InfoModal setShowModal={showInfoModalHandler} />
            </Modal>
            <Modal
                active={active}
                setActive={setActive}
                showModal={showProductModal}
                setShowModal={showProducModalHandler}
                styleModal={`product-modal ${colorScheme === "dark" ? "dark" : ""}`}
                product={true}
            >
                <ProductModal setShowModal={showProducModalHandler} />
            </Modal>
        </div>
    )
}

export default MainLayout;
