import React from "react";
import ringIcon from "../../assets/images/ring.svg";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setShowModal } from "../../redux/slices/main";

const Ring: React.FC = () => {
    const dispatch = useAppDispatch();

    const onRingClickHandler = () => {
        dispatch(setShowModal(true));
    }

    return (
        <div className="ring">
            <div className="ring-image__wrapper" onClick={onRingClickHandler}>
                <img src={ringIcon} alt="" />
            </div>
        </div>
    )
}

export default Ring;