import React, {useEffect, useState} from 'react';
import {useAppDispatch} from "../../../../hooks/useAppDispatch";
import {setShowFavorite} from "../../../../redux/slices/main";
import {useAppSelector} from "../../../../hooks/useAppSelector";
import emptyFavorite from '../../../../assets/images/favorite-empty.svg';
import emptyFavoriteDark from '../../../../assets/images/favorite-empty-dark.svg';
import FavoriteItem from "../../FavoriteItem/FavoriteItem";
import {useMantineColorScheme} from "@mantine/core";
import {AnimatePresence, motion} from 'framer-motion';

function FavoriteModal() {
    const { colorScheme } = useMantineColorScheme();

    const dispatch = useAppDispatch();
    const [active, setActive] = useState(false);
    const [bgActive, setBg] = useState(true);

    const favoriteItems = useAppSelector(state => state.menu.favoriteItems);

    useEffect(() => {
        setActive(true);
    }, [])

    return (
        <>
            {
                bgActive &&
                <span onClick={() => dispatch(setShowFavorite(false))} className="favorite-close-bg"></span>
            }
            <AnimatePresence>
                {
                    active ?
                        <motion.div
                            initial={{x: '100%', opacity: 0}}
                            animate={{x: '0', opacity: 1}}
                            transition={{duration: 0.3}}
                            exit={{x: '100%', opacity: 0}}
                            className={`favorite-modal ${colorScheme === 'dark' ? 'dark' : 'light'}`}>
                            <button className="close" onClick={() => {
                                setActive(false)
                                setBg(false)
                                setTimeout(() => dispatch(setShowFavorite(false)), 500)

                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                    <path d="M9.33333 26.833L18.0834 18.0831M18.0834 18.0831L26.8333 9.33301M18.0834 18.0831L9.33333 9.33301M18.0834 18.0831L26.8333 26.833" stroke={colorScheme === 'dark' ? '#F8F9FA' : '#101113'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                            <h1 className="modal-title">Избранное</h1>
                            <div className="favorite-content">
                                {favoriteItems.length > 0 ?
                                    favoriteItems.map(item => {
                                        return (
                                            <FavoriteItem unit={item}/>
                                        )
                                    })
                                    :
                                    <div className={'favorite-empty--content'}>
                                        <img src={colorScheme === 'dark' ? emptyFavoriteDark : emptyFavorite} alt=""/>
                                        <p className="empty-title">В избранном ничего нет...</p>
                                        <p className="empty-subtitle">Не переживайте! Начните добавлять блюда, нажав на маленькое сердечко</p>
                                    </div>
                                }
                            </div>
                        </motion.div>  : null
                }
            </AnimatePresence>


        </>

    );
}

export default FavoriteModal;