import { Swiper, SwiperSlide } from 'swiper/react';
//import sliderImg from '../img/menu category-slider-min.png'
import 'swiper/css/pagination';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';

import img1 from '../img/slider/gastrobar-1-min.png';
import img2 from '../img/slider/gastrobar-2-min.png';

import img3 from '../img/slider/japengo-1-min.png';
import img4 from '../img/slider/japengo-2-min.png';

import img5 from '../img/slider/more-1-min.png';
import img6 from '../img/slider/more-2-min.png';

export default () => {
    return (
        <Swiper
            spaceBetween={145}
            slidesPerView={3}
            modules={[Navigation, Pagination]}
            navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }}
            pagination={true}
            breakpoints={{
                300: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                },
                620: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                800: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 145,
                },
            }}
        >
            <SwiperSlide>
                <img src={img1} alt="Меню наших клиентов" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img2} alt="Меню наших клиентов" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img3} alt="Меню наших клиентов" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img4} alt="Меню наших клиентов" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img5} alt="Меню наших клиентов" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img6} alt="Меню наших клиентов" />
            </SwiperSlide>
            
            <div className="swiper-bottom">
                <div className="swiper-button-prev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="11" viewBox="0 0 5 11" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M4.90499 0.115065C4.77832 -0.0383551 4.57293 -0.0383551 4.44626 0.115065L0 5.5L4.44626 10.8849C4.57293 11.0384 4.77832 11.0384 4.90499 10.8849C5.03167 10.7315 5.03167 10.4828 4.90499 10.3294L0.917474 5.5L4.90499 0.670649C5.03167 0.517229 5.03167 0.268485 4.90499 0.115065Z"
                            fill="#090909" />
                    </svg>
                </div>
                <div className="swiper-button-next">
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="11" viewBox="0 0 5 11" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M0.0950074 10.8849C0.221684 11.0384 0.427067 11.0384 0.553744 10.8849L5 5.5L0.553744 0.115066C0.427067 -0.0383549 0.221684 -0.0383549 0.0950074 0.115066C-0.0316691 0.268486 -0.0316691 0.517229 0.0950074 0.67065L4.08253 5.5L0.0950074 10.3294C-0.0316691 10.4828 -0.0316691 10.7315 0.0950074 10.8849Z"
                            fill="#090909" />
                    </svg>
                </div>
            </div>
        </Swiper>
    );
};
