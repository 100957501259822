import React, {useEffect} from "react";
import ModalInner from "./ModalInner";
import {AnimatePresence, motion} from "framer-motion";
import {useMantineColorScheme} from "@mantine/core";

type ModalProps = {
    children: React.ReactNode,
    showModal: boolean,
    setShowModal: (value: boolean) => void,
    styleModal: string,
    active: boolean,
    setActive: any,
    product?: boolean
}

const Modal: React.FC<ModalProps> = ({showModal, children, setShowModal, styleModal, active, setActive, product}) => {
  const { colorScheme } = useMantineColorScheme();

    return (
        <>
            {
                showModal ?

                        <div className="modal">
                          {
                            product &&
                            <div
                              className={`modal-close product-close desktop ${colorScheme === "dark" ? "dark" : ""}`}
                              onClick={() => setShowModal(false)}
                            >
                              <div className="modal-close__inner">
                                <div className="modal-close__line modal-close__line--first"></div>
                                <div className="modal-close__line modal-close__line--second"></div>
                              </div>
                            </div>
                          }
                            <ModalInner content={children} setShowModal={setShowModal} styleModal={styleModal} active={active} setActive={setActive}/>
                        </div>
                    : null
            }
        </>


    )
}

export default Modal;
