import React from "react";
import { IMenuItem } from "../../../types";
import Card from "../../common/card";

type MenuCategorySectionProps = {
    title: string,
    items?: IMenuItem[]
}

const MenuCategorySection: React.FC<MenuCategorySectionProps> = ({title, items}) => {

    if(items?.length === 0) {
      return (
        null
      )
    }

    return (
            <div className="menu-category__section">
                <h2 className="menu-category__title">{title}</h2>
                <div className="menu-category__items">
                    {items?.map((unit, index) =>
                        <Card unit={unit} key={`${unit.title}_${index}`} />
                    )}
                </div>
            </div>
    )
}

export default MenuCategorySection;
