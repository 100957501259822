import React, { useState } from "react";
import { menuItems } from "../../../data";
import AddedProduct from "../../common/added_product";
import ForItemsSlider from "../../common/sliders/ForItemsSlider";
import MainBtn from "../../common/buttons/MainBtn";
import { useMantineColorScheme } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useAppSelector";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setCart, setCartLength, setCartTotalPrice } from "../../../redux/slices/menu";
import ym from 'react-yandex-metrika';

type OrderStartProps = {
  setSuccesfulOrderHandler: () => void
}

const OrderStart: React.FC<OrderStartProps> = ({ setSuccesfulOrderHandler }) => {
  const { colorScheme } = useMantineColorScheme();
  const { cartTotalPrice, cartItems } = useAppSelector(state => state.menu)
  const { restaurantInfo } = useAppSelector(state => state.main)
  const [wishes, changeWishes] = useState('');
  const navigate = useNavigate();
  const [cards, setCards] = useState<{
    _id: string,
    categoryId: string,
    title: string,
    description: string,
    price: number,
    oldPrice: number,
    quantity: number,
    measure: string,
    servingTime: number,
    kilocalories: number,
    proteins: number,
    fats: number,
    carbohydrates: number,
    ingredients: string,
    allergens: [
      string
    ],
    active: boolean,
    image: string,
    updatedAt: string
  }[]>([])

  const params = useParams();
  const dispatch = useDispatch();

  const sendOrder = async () => {
    if (restaurantInfo && params.table) {
      let send = {
        table: params.table,
        wishes: wishes,
        dishes: [...cartItems.map(item => {
          if (item.activeOption) {
            if (item.activeAdditionals && item.activeAdditionals.length > 0) {
              return { _id: item._id, quantity: item.count, option: item.activeOption.title, additionalls: [...item.activeAdditionals.map(item => item.title)] }
            }
            return { _id: item._id, quantity: item.count, option: item.activeOption.title }
          } else {
            if (item.activeAdditionals && item.activeAdditionals.length > 0) {
              return { _id: item._id, quantity: item.count, additionalls: [...item.activeAdditionals.map(item => item.title)] }
            }
            return { _id: item._id, quantity: item.count }
          }
        })]
      };
      try {
        const result = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${restaurantInfo._id}/orders/guest`, {
          ...send
        })
        if (result.status === 201) {
          dispatch(setCart([]));
          dispatch(setCartLength(0));
          dispatch(setCartTotalPrice(0));
          localStorage.removeItem('cartItems');
          localStorage.removeItem('cartLength');
          localStorage.removeItem('cartTotalPrice');
          setSuccesfulOrderHandler();
          ym('reachGoal', 'orderComplite', {
            restaurantId: restaurantInfo?._id, 
            restaurantTitle: restaurantInfo?.title, 
            uniqueDishesCount: cartItems?.length, 
            tableNumber: params?.table
          });
        }
      } catch (e) {

      }
    }
  }

  return (
    <>
      <div className="container">
        <button onClick={() => {
           dispatch(setCart([]));
           dispatch(setCartLength(0));
           dispatch(setCartTotalPrice(0));
           localStorage.removeItem('cartItems');
           localStorage.removeItem('cartLength');
           localStorage.removeItem('cartTotalPrice');
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill={`${colorScheme === "dark" ? "#fff" : "#000"}`} d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1l-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/></svg>
        </button>
        <div className="title">Ваш заказ</div>
        <div className={`order-table ${colorScheme === "dark" ? "dark" : ""}`}>Стол № {params.table}</div>
        <div className="order-inner">
          <div className="order-cards">
            {cartItems.map((item, index) =>
              <AddedProduct unit={item} key={`${item.title}_${index}`} />
            )}
          </div>
          <div className="order-return">
            <MainBtn text="Вернуться в меню" type="empty" onClick={() => navigate(`/${params.code}/${params.table}/menu`)} />
          </div>
          <div className="order-slider">
            <ForItemsSlider cart={true} title="Добавить к заказу?" />
          </div>
          <div className="order-wishes__wrapper">
            <div className="order-wishes">
              <div className="order-wishes__title">Пожелания к заказу</div>
              <textarea onChange={(e) => changeWishes(e.target.value)} value={wishes} placeholder={'Введите пожелания'} className={`order-wishes__text ${colorScheme === "dark" ? "dark" : ""}`}></textarea>
              <div className={`order-sum ${colorScheme === "dark" ? "dark" : ""}`}>
                <div className="order-sum__name">Сумма заказа</div>
                <div className="order-sum__value">{cartTotalPrice} ₽</div>
              </div>
              <div className="order-separator"></div>
              <div className="order-total" style={{marginBottom: restaurantInfo?.isShowcaseMode ? '0' : ''}}>
                <div className="order-total__name">Итого</div>
                <div className="order-total__value">{cartTotalPrice} ₽</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        (cartTotalPrice > 0 && !restaurantInfo?.isShowcaseMode) &&
        <div className="order-btn">
          <MainBtn text="Оформить" onClick={sendOrder} />
        </div>
      }
    </>
  )
}

export default OrderStart;
