import React, {useEffect, useRef} from "react";
import shapeLight from "../../../assets/images/shape.svg";
import shapeDark from "../../../assets/images/shape-dark.svg";
import MainBtn from "../../common/buttons/MainBtn";
import {useMantineColorScheme} from "@mantine/core";
import {useLocation, useNavigate, useParams} from "react-router-dom";

const OrderSuccessful: React.FC = () => {

    const { colorScheme } = useMantineColorScheme();
    const navigate = useNavigate();
    const params = useParams();
    const menuReturn = () => {
        navigate(`/${params.code}/${params.table}`)
    }


    return (
        <div className="container">
            <div className="order-successful">
                <div className="order-successful__center-wrapper">
                    <div className="order-successful__center">
                        <div className="order-successful__shape">
                            <img src={colorScheme === 'dark' ? shapeDark : shapeLight} alt="" />
                        </div>
                        <h3 className="order-successful__thanks">Спасибо за заказ!</h3>
                        <div className="order-successful__info">Мы уже передали информацию вашему официанту</div>
                    </div>
                </div>
                <div className="order-successful__btn">
                    <MainBtn text="Вернуться в меню" onClick={menuReturn}/>
                </div>
            </div>
        </div>
    )
}

export default OrderSuccessful;
