import { Carousel } from "@mantine/carousel";
import React, {useCallback, useEffect, useState} from "react";
import Card from "../card";
import sliderArrow from "../../../assets/images/home_slider_arrow.svg";
import { menuItems } from "../../../data";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
  setActiveCategory,
  setAddToOrder,
  setCategories,
  setPopularProductsId,
  setProducts
} from "../../../redux/slices/menu";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {getAddToOrder, getCategories, getDishes, getPlaceInfo, getPopularId} from "../../../hooks/getPlaceInfo";
import {setRestaurantInfo} from "../../../redux/slices/main";
import {debug} from "util";

type ForItemsSliderProps = {
    title: string
    popular?: boolean

    cart?: boolean
    items?: {
      _id: string,
      categoryId: string,
      title: string,
      description: string,
      price: number,
      oldPrice: number,
      quantity: number,
      measure: string,
      servingTime: number,
      kilocalories: number,
      proteins: number,
      fats: number,
      carbohydrates: number,
      ingredients: string,
      allergens: [
        string
      ],
      active: boolean,
      image: string,
      updatedAt: string
    }[]
}

const ForItemsSlider: React.FC<ForItemsSliderProps> = ({title, popular, cart, items}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const {products, popularProductsId, addToOrder, cartItems} = useAppSelector(state => state.menu)

    const [cards, setCards] = useState<{
      optionsItems: {
        title:	string
        price:	number
        quantity:	number
        measure:	string
      }[] | null
      additionallItems: {
        title:	string
        price:	number
        quantity:	number
        measure:	string
      }[] | null
      _id: string,
      categoryId: string,
      title: string,
      description: string,
      price: number,
      oldPrice: number,
      quantity: number,
      measure: string,
      servingTime: number,
      kilocalories: number,
      proteins: number,
      fats: number,
      carbohydrates: number,
      ingredients: string,
      allergens: [
        string
      ],
      active: boolean,
      image: string,
      updatedAt: string
    }[]>([])

  const params = useParams();
  const start = () => {
    if(params.code && params.table){
      getPlaceInfo(params.code).then(response => {
          dispatch(setRestaurantInfo({...response}))
          getDishes(response._id).then(products => {
            if(products) {
              dispatch(setProducts([...products]))
            }
          })
          getPopularId(response._id).then(id => {
            if(id) {
              dispatch(setPopularProductsId([...id]))
            }
          })
          getAddToOrder(response._id).then(addToOrder => {
            if(addToOrder) {
              dispatch(setAddToOrder([...addToOrder]))
            }
          })
      });
    } else {
      navigate('/error')
    }
  }

  const [cartPrevLength, setPrevLength] = useState(0);
  const [startEffect, setStart] = useState(true);

  useEffect(() => {
    if(popular) {
      if(products && popularProductsId) {
        setCards([...products.filter(item => popularProductsId.includes(item._id))])
      } else {
        start()
      }
    } else if(cart) {
      if(products && addToOrder) {
        if(cards.length === 0 || cartItems.length !== cartPrevLength) {
          setCards([...products.filter(item => addToOrder.includes(item._id) && !cartItems.map(item => item._id).includes(item._id))])
          setPrevLength(cartItems.length)
        }
      } else {
        if(startEffect) {
          start()
          setStart(false)
        }
      }
    } else {
      if(products) {
        setCards([...products])
      } else {
        start()
      }
    }

  }, [products, popularProductsId, addToOrder, cartItems])

    const slides = cards.map((item, index) => (
        <Carousel.Slide key={`${item.title}_${index}`}>
            <Card unit={item} />
        </Carousel.Slide>
    ));

    return (
        <div className="for-items">
          {
            cards.length > 0 &&
            <>
              {location.pathname !== `/${params.code}/${params.table}/order` ?
                <div onClick={() => {
                  dispatch(setActiveCategory({title: 'Популярные'}));
                  navigate(`/${params.code}/${params.table}/menu`)
                }
                } className="for-items__title">{title}
                  <img src={sliderArrow} alt=""/>
                </div> :
                <div className="for-items__title" style={{cursor: 'default'}}>{title}</div>
              }
              <div className="for-items__slider">
                <Carousel
                  slideSize="25%"
                  slideGap="20px"
                  align="start"
                  slidesToScroll={1}
                  containScroll="trimSnaps"
                  skipSnaps={true}
                  styles={{
                    control: {
                      border: 'none',
                      background: 'none',
                      boxShadow: 'none',
                      marginRight: '20px',
                      '&[data-inactive]': {
                        opacity: 0,
                        cursor: 'default',
                      },
                    },
                    controls: {
                      marginTop: '-140px',
                      paddingLeft: '700px'
                    }
                  }}
                >
                  {slides}
                </Carousel>
              </div>
            </>
          }

        </div>
    )
}

export default ForItemsSlider;
