import React, {useEffect, useRef, useState} from 'react';
import search from "../../../assets/images/search.svg";
import searchWhite from "../../../assets/images/search_white.svg";
import {useMantineColorScheme} from "@mantine/core";
import {NavLink} from "react-router-dom";
import arrow from '../../../assets/images/arrow_back-search.svg';
import arrowLight from '../../../assets/images/arrow_back-search-light.svg';
import {useDispatch} from "react-redux";
import {setShowSearch} from "../../../redux/slices/main";
import {changeSearchValue} from "../../../redux/slices/menu";
import {useAppSelector} from "../../../hooks/useAppSelector";
function SearchBlock() {

    const { colorScheme } = useMantineColorScheme();

    const {searchValue} = useAppSelector(state => state.menu);

    const inputRef: any = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        inputRef.current.focus();
    }, [])

    return (
        <div className={`search-input-wrap`}>
            <button onClick={() => dispatch(setShowSearch(false))} className={'close-search'}>
                <img src={colorScheme === 'dark' ? arrowLight : arrow} alt=""/>
            </button>
            <input value={searchValue} onChange={(e) => dispatch(changeSearchValue(e.target.value))} ref={inputRef} type="text" placeholder={'Поиск'}/>
            <button className="clear-search" onClick={() => {
              dispatch(changeSearchValue(''))
                inputRef.current.focus();
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M6.40002 18.4004L12.4 12.4004M12.4 12.4004L18.4 6.40039M12.4 12.4004L6.40002 6.40039M12.4 12.4004L18.4 18.4004" stroke="#868E96" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </div>
    );
}

export default SearchBlock;
