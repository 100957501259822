import { useMantineColorScheme } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import DishInfoItem from "../../dish/DishInfoItem";
import timeIcon from "../../../../assets/images/time.svg";
import weightIcon from "../../../../assets/images/weight.svg";
import favorite from "../../../../assets/images/favorite.svg";
import favoriteActive from "../../../../assets/images/favorite_active.svg";
import ProductMeasuresItem from "./ProductMeasuresItem";
import { addToFavorite, removeFromFavorite } from "../../../../redux/slices/menu";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { AnimatePresence, motion } from "framer-motion";
import Button from "./Button";
import { useLocation } from "react-router-dom";
import ym from 'react-yandex-metrika';

type ProductModalProps = {
  setShowModal: (value: boolean) => void,
}

const ProductModal: React.FC<ProductModalProps> = ({ setShowModal }) => {
  const { cartItems } = useAppSelector(state => state.menu);
  const [favoriteValue, setFavoriteValue] = useState<boolean>(false);
  const { colorScheme } = useMantineColorScheme();
  const productForModal = useAppSelector(state => state.main.productForModal);
  const showProductModal = useAppSelector(state => state.main.showFavorite);
  const favoriteItems = useAppSelector(state => state.menu.favoriteItems);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const setFavoriteValueHandler = () => {
    if (!favoriteValue) {
      if (productForModal) {
        dispatch(addToFavorite(productForModal));
      }
    } else {
      if (productForModal) {
        dispatch(removeFromFavorite(productForModal));
        setFavoriteValue(false);
      }
    }
  }

  useEffect(() => {
    favoriteItems.map(item => {
      if (item._id === productForModal?._id) {
        setFavoriteValue(true);
      }
    })
  }, [favoriteItems])

  const [activeOption, setActiveOption] = useState<{
    _id: string,
    title: string,
    price: number
  } | undefined>(productForModal?.activeOption ? productForModal?.activeOption : productForModal?.optionsItems ? { _id: `0${productForModal?.optionsItems[0].title}`, title: productForModal?.optionsItems[0].title, price: productForModal?.optionsItems[0].price } : undefined);

  const [price, setPrice] = useState<number | undefined>(productForModal?.activeOption ? productForModal?.activeOption.price + productForModal?.price : productForModal?.optionsItems ? productForModal?.optionsItems[0].price + productForModal?.price : productForModal?.price)

  const [count, setCount] = useState(1);

  const [activeDops, setDops] = useState<{
    title: string,
    price: number,
    _id: string,
  }[]>([])

  useEffect(() => {
    if (cartItems.length > 0 && location.pathname.substring(location.pathname.length - 5) === 'order') {
      let count = 0;
      cartItems.filter(item => item._id === productForModal?._id).map(item => {
        if (item.activeOption && productForModal?.activeOption) {
          if (item.activeOption._id === productForModal?.activeOption?._id) {
            setCount(item.count)
          }
        } else {
          setCount(item.count)
        }
      })
    } else {
      setCount(1)
    }
  }, [cartItems])

  const [addPrice, setAddPrice] = useState(0);


  useEffect(() => {
    let priceLocal = 0;

    if (productForModal && productForModal.activeAdditionals) {
      productForModal.activeAdditionals.map(item => {
        if (item.price) {
          priceLocal += item.price
        }
      })
    } else if (productForModal) {
      activeDops.map(item => {
        if (item.price) {
          priceLocal += item.price
        }
      })
    }

    setAddPrice(priceLocal);
  }, [productForModal, activeDops])


  useEffect(() => {
    ym('reachGoal', 'dishView', {id: productForModal?._id, title: productForModal?.title});
  }, [productForModal])
  

  return (
    <>
      {productForModal ?
        <AnimatePresence>
          <motion.div
            className="product-modal__content">
            <div className="product-image__wrapper" style={{ backgroundImage: `url(https://s3.vkusclick.ru/${productForModal.image})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
            </div>
            <div className="product-info">
              <div className="product-start">
                <div className="product-title">{productForModal.title}</div>
                <div className="product-favorite" onClick={setFavoriteValueHandler}>
                  <img src={favoriteValue ? favoriteActive : favorite} alt="" />
                </div>
              </div>
              <div className={`product-price ${colorScheme === "dark" ? "dark" : ""}`}>{productForModal.oldPrice &&
                <span>{productForModal.oldPrice + ' ₽'}</span>}{productForModal.price} ₽</div>
              <div className="product-description">{productForModal.description}</div>
              <div className="dish-info">
                <DishInfoItem measure={productForModal.measure} type="time" image={timeIcon} quantity={productForModal.servingTime} />
                <DishInfoItem measure={productForModal.measure} type="weight" image={weightIcon} quantity={productForModal.quantity} />
              </div>
              <div className={`product-measures `}>
                <ProductMeasuresItem name="Ккал" value={productForModal.kilocalories ? productForModal.kilocalories : '-'} />
                <ProductMeasuresItem name="Белки" value={productForModal.proteins ? productForModal.proteins : '-'} />
                <ProductMeasuresItem name="Жиры" value={productForModal.fats ? productForModal.fats : '-'} />
                <ProductMeasuresItem name="Углеводы" value={productForModal.carbohydrates ? productForModal.carbohydrates : '-'} />
              </div>
            </div>
            <div className="product-buttons">
              <Button count={count} setCount={setCount} activeAdditionals={activeDops} activeOption={activeOption} unit={productForModal} />
              <div className="total-price__options">
                <p className="modal-title__mobile">{productForModal.title}</p>
                <p>{price && count * price > 0 ? `Сумма` : ''}</p>
                <p className="price">{location.pathname.substring(location.pathname.length - 5) === 'order' && !showProductModal ? price && count * price > 0 ? `${count * price} ₽` : '' : price && count * price > 0 ? `${count * (price + addPrice)} ₽` : ''}</p>
              </div>
            </div>
            {
              productForModal.allergens.length > 0 &&
              <div className="product-allergens">
                <h4 className="product-allergens__title">Аллергены</h4>
                <div className="product-allergens__items">
                  {productForModal.allergens.map((item, index) =>
                    <div
                      className={`product-allergens__item ${colorScheme === "dark" ? "dark" : ""}`}
                      key={`${item}_${index}`}
                    >{item}</div>
                  )}
                </div>
              </div>
            }
            {
              productForModal.ingredients &&
              <div className="product-ingredients">
                <h4 className="product-ingredients__title">Ингредиенты</h4>
                <div className={`product-ingredients__text ${colorScheme === "dark" ? "dark" : ""}`}>{productForModal.ingredients}</div>
              </div>
            }
            {
              productForModal.optionsItems &&
              <div className="product-options">
                <h4 className="product-options__title">Опции</h4>
                {
                  location.pathname.substring(location.pathname.length - 5) === 'order' && !showProductModal ?
                    <p>{productForModal?.activeOption?.title}</p> :
                    <div className="options">
                      {
                        productForModal.optionsItems.map((option, i) => {
                          return (
                            <div className={'option'} onClick={() => {
                              setActiveOption({
                                _id: i + option.title,
                                title: option.title,
                                price: option.price
                              })
                              setPrice(option.price ? productForModal.price + option.price : productForModal.price)
                            }} key={option.title + option.price} style={{ borderColor: colorScheme === "dark" ? '#2C2E33' : '#DEE2E6' }}>
                              <div className="option-info">
                                <p className="option-title">{option.title}</p>
                                <p className="option-quantity">{option.quantity ? `(${option.quantity}${option.measure === 'grams' ? "г" : option.measure === 'kilograms' ? 'кг' : option.measure === 'milliliters' ? 'мл' : option.measure === 'liters' ? 'л' : option.measure === 'pieces' ? 'шт' : option.measure === 'portions' ? 'шт' : ''})` : ''}</p>
                                <p className="option-price">{option.price ? `+${option.price}₽` : ''}</p>
                              </div>
                              <div className="option-radio" style={{ borderColor: colorScheme === "dark" ? '#2C2E33' : '#DEE2E6' }}>
                                {
                                  activeOption && activeOption._id === i + option.title &&
                                  <span className="active-dot"></span>
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                }
              </div>
            }
            {
              productForModal.additionallItems &&
              <div className="product-additionall">
                {
                  location.pathname.substring(location.pathname.length - 5) === 'order' && productForModal?.activeAdditionals && productForModal?.activeAdditionals.length === 0 ?
                    <></> :
                    <h4 className="product-additionall__title">Дополнительно</h4>
                }
                {
                  location.pathname.substring(location.pathname.length - 5) === 'order' && !showProductModal ?
                    <p>{productForModal?.activeAdditionals?.map(item => item.title).join(', ')}</p> :
                    <div className="additionalls">
                      {
                        productForModal.additionallItems.map((option, i) => {
                          return (
                            <div className={'additionall'} onClick={() => {
                              if (!activeDops.map(item => item._id).includes(i + option.title)) {
                                setDops([...activeDops, { _id: i + option.title, price: option.price, title: option.title }])
                              } else {
                                setDops([...activeDops.filter(item => item._id !== (i + option.title))])
                              }
                            }} key={option.title + option.price} style={{ borderColor: colorScheme === "dark" ? '#2C2E33' : '#DEE2E6' }}>
                              <div className="additionall-info">
                                <p className="additionall-title">{option.title}</p>
                                <p className="additionall-quantity">{option.quantity ? `(${option.quantity}${option.measure === 'grams' ? "г" : option.measure === 'kilograms' ? 'кг' : option.measure === 'milliliters' ? 'мл' : option.measure === 'liters' ? 'л' : option.measure === 'pieces' ? 'шт' : option.measure === 'portions' ? 'шт' : ''})` : ''}</p>
                                <p className="additionall-price">{option.price ? `+${option.price}₽` : ''}</p>
                              </div>
                              <div className="additionall-radio" style={{ borderColor: colorScheme === "dark" ? '#2C2E33' : '#DEE2E6' }}>
                                {
                                  activeDops && activeDops.map(item => item._id).includes(i + option.title) &&
                                  <>
                                    <span className="active-dot"></span>
                                    <svg className="option-check" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="m5 12l5 5L20 7" /></svg>
                                  </>

                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                }

              </div>
            }
          </motion.div>
        </AnimatePresence>
        : ""
      }
    </>

  )
}

export default ProductModal;
