import { useMantineColorScheme } from "@mantine/core";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {AnimatePresence, motion, useReducedMotion} from "framer-motion";
import {useAppSelector} from "../../../../hooks/useAppSelector";

type ModalInnerProps = {
    content: React.ReactNode,
    setShowModal: (value: boolean) => void,
    styleModal: string,
    active: boolean,
    setActive: any,
}

const ModalInner: React.FC<ModalInnerProps> = ({content, setShowModal, styleModal, setActive, active,} ) => {
    const { colorScheme } = useMantineColorScheme();
    const modalRef = useRef<HTMLInputElement>(null);

    const handleOutsideClick = (e: any) => {
        const path = e.path || (e.composedPath && e.composedPath())
        if(!path.includes(modalRef.current)) {
            setShowModal(false);
        }
    }

    useEffect(() => {
        const addListener = () => {
            document.body.addEventListener("click", handleOutsideClick);
        }
        setTimeout(addListener, 1)
        setActive(true);
        return () => {
            document.body.removeEventListener("click", handleOutsideClick);
        }
    }, [])

    return (
        <>
            <AnimatePresence>
                
                {active ?
                    <motion.div
                        key={'modal-inner'}
                        initial={{y: '100%', opacity: 0}}
                        animate={{y: '0', opacity: 1}}
                        transition={{duration: 0.3}}
                        className="modal-inner">
                 
                        <div className={`${styleModal} ${colorScheme === "dark" ? "dark" : ""}`} ref={modalRef}>
                            <div
                              className={`modal-close product-close mobile ${colorScheme === "dark" ? "dark" : ""}`}
                              onClick={() => setShowModal(false)}
                            >
                              <div className="modal-close__inner">
                                <div className="modal-close__line modal-close__line--first"></div>
                                <div className="modal-close__line modal-close__line--second"></div>
                              </div>
                            </div>
                            <div className="modal-content">
                                {content}
                            </div>
                        </div>

                    </motion.div>
                    : null
                }

            </AnimatePresence>

        </>

    )
}

export default ModalInner;
