import { createSlice } from "@reduxjs/toolkit";
import { MenuState, cartItem } from "../../types";
import { byField } from "../../hooks/sort";

const initialState: MenuState = {
  categoriesError: false,
  categories: [],
  menuItems: [],
  cartItems: [],
  cartLength: 0,
  cartTotalPrice: 0,
  favoriteItems: [],
  searchValue: ''
}

const MenuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setAddToOrder(state, { payload }) {
      state.addToOrder = [...payload];
    },
    setActiveCategory(state, { payload }) {
      state.activeCategory = payload;
    },
    addToCart(state, { payload }) {
      if (state.cartItems.length > 0) {
        if ((payload.activeOption || (payload.activeAdditionals && payload.activeAdditionals.length > 0))) {
          if (state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption ? item.activeOption._id : '')}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption ? payload.activeOption._id : '')}${JSON.stringify(payload.activeAdditionals)}`)) {
            // @ts-ignore
            state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption ? item.activeOption._id : '')}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption ? payload.activeOption._id : '')}${JSON.stringify(payload.activeAdditionals)}`).count += payload.count
            // @ts-ignore
            state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption ? item.activeOption._id : '')}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption ? payload.activeOption._id : '')}${JSON.stringify(payload.activeAdditionals)}`).price += payload.price
          } else {
            state.cartItems.push({ ...payload });
          }
        } else {
          if (state.cartItems.find(item => item._id === payload._id)) {
            // @ts-ignore
            state.cartItems.find(item => item._id === payload._id).count += payload.count
            // @ts-ignore
            state.cartItems.find(item => item._id === payload._id).price += payload.price
          } else {
            state.cartItems.push({ ...payload });
          }
        }
      } else {
        state.cartItems.push({ ...payload });
      }

      state.cartLength++
      if (!isNaN(state.cartTotalPrice)) {
        state.cartTotalPrice = (typeof state.cartTotalPrice === 'string' ? parseInt(state.cartTotalPrice) : state.cartTotalPrice) + payload.price
      } else {
        state.cartTotalPrice = payload.price
      }

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      localStorage.setItem('cartLength', JSON.stringify(state.cartLength));
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice));
    },
    removeFromCart(state, { payload }) {
      if (payload.activeAdditionals || payload.activeOption) {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption ? item.activeOption._id : '')}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption ? payload.activeOption._id : '')}${JSON.stringify(payload.activeAdditionals)}`).count = 0

        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption ? item.activeOption._id : '')}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption ? payload.activeOption._id : '')}${JSON.stringify(payload.activeAdditionals)}`).price -= payload.price / payload.count
      } else {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).count = 0

        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).price -= payload.price / payload.count
      }

      state.cartItems = [...state.cartItems.filter(item => item.count > 0)]

      let totalPrice = 0;

      state.cartItems.map(item => {
        totalPrice += item.price;
      })

      state.cartTotalPrice = totalPrice;

      localStorage.setItem('cartItems', JSON.stringify([...state.cartItems]))
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice))
    },
    removeCountFromCart(state, { payload }) {
      if ((payload.activeAdditionals && payload.activeAdditionals.length > 0) || payload.activeOption) {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption._id)}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption._id)}${JSON.stringify(payload.activeAdditionals)}`).count--
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption._id)}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption._id)}${JSON.stringify(payload.activeAdditionals)}`).price -= payload.price / payload.count
      } else {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).count--
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).price -= payload.price / payload.count
      }

      state.cartItems = [...state.cartItems.filter(item => item.count > 0)]

      let totalPrice = 0;

      state.cartItems.map(item => {
        totalPrice += item.price;
      })

      state.cartTotalPrice = totalPrice;

      localStorage.setItem('cartItems', JSON.stringify([...state.cartItems]))
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice))
    },
    addCountProductCart(state, { payload }) {
      if (payload.activeOption || (payload.activeAdditionals && payload.activeAdditionals.length > 0)) {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption._id)}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption._id)}${JSON.stringify(payload.activeAdditionals)}`).price += payload.price / payload.count

        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption._id)}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption._id)}${JSON.stringify(payload.activeAdditionals)}`).count++
      } else {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).count++
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).price += payload.price / payload.count
      }

      let price = 0;

      state.cartItems.map(item => {
        price += item.price;
      })

      state.cartTotalPrice = price;

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      localStorage.setItem('cartLength', JSON.stringify(state.cartLength));
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice));
    },
    setCart(state, { payload }) {
      state.cartItems = [...payload]
    },
    changeCountInCart(state, { payload }) {
      if (payload.activeOption) {
        state.cartItems = [...state.cartItems.map(item => {
          if (item._id === payload._id && item.activeOption._id === payload.activeOption._id) {
            item.price = payload.count * (item.price / (item.count))
            if (item.oldPrice) {
              item.oldPrice = payload.count * (item.oldPrice / (item.count))
            }
            item.count = payload.count
          }
          return item
        })]
      } else {
        state.cartItems = [...state.cartItems.map(item => {
          if (item._id === payload._id) {
            item.price = payload.count * (item.price / (item.count))
            if (item.oldPrice) {
              item.oldPrice = payload.count * (item.oldPrice / (item.count))
            }
            item.count = payload.count
          }
          return item
        })]
      }

      let price = 0;

      state.cartItems.map(item => {
        price += item.price;
      })

      state.cartTotalPrice = price;

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      localStorage.setItem('cartLength', JSON.stringify(state.cartLength));
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice));
    },
    setCartTotalPrice(state, { payload }) {
      state.cartTotalPrice = payload
    },
    setCartLength(state, { payload }) {
      state.cartLength = payload;
    },
    addToFavorite(state, { payload }) {
      state.favoriteItems.push(payload);
      localStorage.setItem('favoriteItems', JSON.stringify([...state.favoriteItems]));
    },
    setFavorite(state, { payload }) {
      state.favoriteItems = [...payload];
    },
    removeFromFavorite(state, { payload }) {
      state.favoriteItems = state.favoriteItems.filter(item => item._id !== payload._id);
      localStorage.setItem('favoriteItems', JSON.stringify([...state.favoriteItems.filter(item => item._id !== payload._id)]));
    },
    setCategories(state, { payload }) {
      state.categories = [{ _id: '0', title: 'Популярные', active: true }, ...payload.filter((item: any) => {
        if (item.active) {
          if (item.activeTimeStart && item.activeTimeEnd && item.activeTimeStart !== 'null' && item.activeTimeEnd !== 'null') {
            const startDate = new Date(JSON.parse(item.activeTimeStart));
            const endDate = new Date(JSON.parse(item.activeTimeEnd))
            if (startDate.getHours() < new Date().getHours()) {
              if (endDate.getHours() > new Date().getHours()) {
                return item
              } else if (endDate.getHours() === new Date().getHours()) {
                if (endDate.getMinutes() >= new Date().getMinutes()) {
                  return item
                }
              }
            } else if (startDate.getHours() === new Date().getHours()) {
              if (startDate.getMinutes() <= new Date().getMinutes()) {
                if (endDate.getHours() > new Date().getHours()) {
                  return item
                } else if (endDate.getHours() === new Date().getHours()) {
                  if (endDate.getMinutes() >= new Date().getMinutes()) {
                    return item
                  }
                }
              }
            }

          }
          else return item
        }
      }).sort(byField('priority')).reverse()]
      if (state.categories.filter(item => item.title !== 'Популярные').length === 0) {
        state.categoriesError = true;
      }
    },
    setProducts(state, { payload }) {
      state.products = [...payload.filter((item: any) => item.active && item.image !== '')]
    },
    setPopularProductsId(state, { payload }) {
      state.popularProductsId = [...payload];
    },
    changeSearchValue(state, { payload }) {
      state.searchValue = payload;
    }
  }
})

export default MenuSlice.reducer;

export const {
  setActiveCategory,
  addToCart,
  removeFromCart,
  addToFavorite,
  removeFromFavorite,
  setCategories,
  setProducts,
  changeSearchValue,
  setPopularProductsId,
  setFavorite,
  setCart,
  setCartTotalPrice,
  setCartLength,
  setAddToOrder,
  addCountProductCart,
  removeCountFromCart,
  changeCountInCart
} = MenuSlice.actions;
