import React from 'react';
import {NavLink, useParams} from "react-router-dom";
import {useMantineColorScheme} from "@mantine/core";

function CartBtn({text}: {text: string}) {

    const { colorScheme } = useMantineColorScheme();
    const params = useParams();

    return (
        <div className={`cart-btn-wrap ${colorScheme === "dark" ? "dark" : ''}`}>
            <NavLink
                className={`cart-btn`}
                to={`/${params.code}/${params.table}/order`}
            >{text}</NavLink>
        </div>

    );
}

export default CartBtn;
