import { useMantineColorScheme } from "@mantine/core";
import React from "react";
import profile from "../../assets/images/profile.svg";
import profileWhite from "../../assets/images/profile_white.svg";
import search from "../../assets/images/search.svg";
import searchWhite from "../../assets/images/search_white.svg";
import heart from "../../assets/images/heart.svg";
import heartWhite from "../../assets/images/heart_white.svg";
import arrowBack from "../../assets/images/arrow_back.svg";
import arrowBackWhite from "../../assets/images/arrow_back_white.svg";
import SwitchToggle from "./SwitchToggle";
import {NavLink, useNavigate} from "react-router-dom";
import FavoriteModal from "./modals/FavoriteModal/FavoriteModal";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {setShowFavorite, setShowSearch} from "../../redux/slices/main";
import Search from "./modals/search";

type HeaderProps = {
    type?: string
}

const Header: React.FC<HeaderProps> = ({type}) => {
    const { colorScheme } = useMantineColorScheme();
    const favoriteShow = useAppSelector(state => state.main.showFavorite);
    const dispatch = useAppDispatch();
    const searchModal = useAppSelector(state => state.main.showSearch);
    const onArrowBackClickHandler = () => {
        /* eslint-disable-next-line no-restricted-globals */
        history.back();
    }

    return (
        <>
            <div className="container">
                <div className="header">
                    <div className="header-start">
                      {
                        !type && type !== "home" &&
                        <div className="header-start__profile-wrapper">
                            <img
                              className={`header-start__profile back ${colorScheme === "dark" ? "dark" : ""}`}
                              src={colorScheme === "dark" ? arrowBackWhite : arrowBack}
                              alt=""
                              onClick={onArrowBackClickHandler}
                            />
                        </div>
                      }
                        <div className={`header-start__switch ${colorScheme === "dark" && "dark"}`}>
                            <SwitchToggle />
                        </div>
                    </div>
                    <div className="header-finish">
                        <button onClick={() => {
                            dispatch(setShowSearch(true))
                        }
                        }>
                            <img
                                className="header-start__search"
                                src={colorScheme === "dark" ? searchWhite : search}
                                alt=""
                            />
                        </button>
                        <img
                            className="header-start__heart"
                            onClick={() => dispatch(setShowFavorite(true))}
                            src={colorScheme === "dark" ? heartWhite : heart}
                            alt=""
                        />
                    </div>
                </div>
            </div>
            {
                favoriteShow && <FavoriteModal/>
            }
            {
                searchModal && <Search/>
            }
        </>
    )
}

export default Header;
