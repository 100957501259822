import React, {useEffect, useState} from "react";
import DishCount from "./../../dish/DishCount";
import {useDispatch} from "react-redux";
import {addToCart, removeFromCart, changeCountInCart} from "../../../../redux/slices/menu";
import {IMenuItem} from "../../../../types";
import { setProductForModal, setShowProductModal } from "../../../../redux/slices/main";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/useAppSelector";

type DishButtonsProps = {
  type?: string,
  activeOption: {
    _id: string,
    title: string,
    price: number
  } | undefined,
  unit: IMenuItem,
  count: number,
  setCount: any,
  activeAdditionals: {
    _id: string,
    title: string,
    price: number
  }[] | undefined,
}

const Button: React.FC<DishButtonsProps> = ({type, unit, activeOption, count, setCount, activeAdditionals}) => {


  const showProductModal = useAppSelector(state => state.main.showFavorite)
  const cartItems = useAppSelector(state => state.menu.cartItems)

  const dispatch = useDispatch();
  const location = useLocation();

  const [btnText, setText] = useState<string | null>(null)

  const addToCartLocal = () => {
    dispatch(setProductForModal(unit))
    dispatch(setShowProductModal(false));
    activeAdditionals?.sort((a, b) => a.title > b.title ? 1 : -1);
    dispatch(addToCart({...unit, activeOption: activeOption ? activeOption : undefined, count: count, activeAdditionals: activeAdditionals, price: unit.price * count}))
  }

  const decreaseHandler = () => {
    if(location.pathname.substring(location.pathname.length - 5) === 'order' && !showProductModal && cartItems.map(item => item._id).includes(unit._id)) {
      setText('Сохранить')
      if(count > 0) {
        setCount(count - 1);
        // dispatch(removeFromCart(unit))
      }
    } else {
      if(count > 1) {
        setCount(count - 1);
        // dispatch(removeFromCart(unit))
      }
    }
    
  }

  const increaseHandler = () => {
    if(location.pathname.substring(location.pathname.length - 5) === 'order' && !showProductModal && cartItems.map(item => item._id).includes(unit._id)) {
      setText('Сохранить')
    }
    setCount(count + 1);
    // dispatch(addToCart({...unit, price: price, activeOption: activeOption ? activeOption : undefined}))
  }


  const removeCart = () => {
    dispatch(removeFromCart(unit))
    dispatch(setProductForModal(unit))
    dispatch(setShowProductModal(false));
  }

  const changeCount = () => {
    dispatch(setProductForModal(unit))
    dispatch(setShowProductModal(false));
    dispatch(changeCountInCart({...unit, count: count}))
  }


  return (
    <div className="dish-buttons" style={{width: '100%'}}>
          <div
            className={`product-modal__addBtn`}
            onClick={() => count > 0 ?location.pathname.substring(location.pathname.length - 5) === 'order' && !showProductModal && cartItems.map(item => item._id).includes(unit._id) ? changeCount() : addToCartLocal() : removeCart()}
            style={{backgroundColor: count === 0 ? '#E70F0F' : ''}}
          >
            {count > 0 ? location.pathname.substring(location.pathname.length - 5) === 'order' && !showProductModal && cartItems.map(item => item._id).includes(unit._id) ? btnText ? btnText : 'Готово' : `Добавить` : 'Удалить'}</div>
          <DishCount
            text={''}
            increaseHandler={increaseHandler}
            decreaseHandler={decreaseHandler}
            cardCount={count}
            type={type}
          />
          
          
    </div>
  )
}

export default Button;
