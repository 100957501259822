import { createSlice } from "@reduxjs/toolkit";
import { MainState } from "../../types";

const initialState: MainState = {
    scroll: 0,
    showModal: false,
    showProductModal: false,
    productForModal: null,
    showFavorite: false,
    headerShow: true,
    showSearch: false,
    showWifiModal: false,
    showInfoModal: false,
}

const MainSlice = createSlice({
    name: "main",
    initialState,
    reducers: {
        setShowInfoModal(state, {payload}) {
          state.showInfoModal = payload;
        },
        setShowModal(state, {payload}) {
            if(payload) {
              state.scroll = document.documentElement.scrollTop
            }
            state.showModal = payload;
        },
        setShowProductModal(state, {payload}) {
          if(payload) {
            state.scroll = document.documentElement.scrollTop
          }
          state.showProductModal = payload;
        },
        setShowWifiModal(state, {payload}) {
          state.showWifiModal = payload;
        },
        setProductForModal(state, {payload}) {
            state.productForModal = payload;
        },
        setShowFavorite(state, {payload}) {
            state.showFavorite = payload;
        },
        setShowHeader(state, {payload}) {
            state.headerShow = payload;
        },
        setShowSearch(state, {payload}) {
            state.showSearch = payload;
        },
        setRestaurantInfo(state, {payload}) {
            state.restaurantInfo = {...payload}
        }
    }
})

export default MainSlice.reducer;

export const {
    setShowModal,
    setShowProductModal,
    setProductForModal,
    setShowFavorite,
    setShowHeader,
    setShowSearch,
    setRestaurantInfo,
    setShowWifiModal,
    setShowInfoModal
} = MainSlice.actions;
