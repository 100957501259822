import React, { useEffect, useState } from "react";
import addedProductImage from "../../../assets/images/added_product.png";
import DishInfoItem from "../dish/DishInfoItem";
import timeIcon from "../../../assets/images/time.svg";
import weightIcon from "../../../assets/images/weight.svg";
import { IMenuItem } from "../../../types";
import DishButtons from "../dish/DishButtons";
import closeIcon from "../../../assets/images/close.svg";
import closeWhiteIcon from "../../../assets/images/close_white.svg";
import { useMantineColorScheme } from "@mantine/core";
import { useDispatch } from "react-redux";
import { removeFromCart, setCart, setCartLength, setCartTotalPrice } from "../../../redux/slices/menu";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { setProductForModal, setShowInfoModal, setShowProductModal } from "../../../redux/slices/main";

type AddedProductProps = {
    unit: IMenuItem
}

const AddedProduct: React.FC<AddedProductProps> = ({ unit }) => {
    const { colorScheme } = useMantineColorScheme();

    const { products } = useAppSelector(state => state.menu);

    const dispatch = useDispatch();

    const removeItem = () => {
        dispatch(removeFromCart(unit))
    }


    return (
        <div className="added-product">
            <div className="added-product__start">
                <div onClick={() => {
                    dispatch(setShowProductModal(true))
                    dispatch(setProductForModal({ ...products?.find(item => item._id === unit._id), activeOption: unit.activeOption, activeAdditionals: unit.activeAdditionals}))
                }} style={{ background: `url(https://s3.vkusclick.ru/${unit.image})` }} className="added-product__image-wrapper">
                </div>
                <div className="added-product__info">
                    <div className="added-product__title" onClick={() => {
                        dispatch(setShowProductModal(true))
                        dispatch(setProductForModal({ ...products?.find(item => item._id === unit._id), activeOption: unit.activeOption, activeAdditionals: unit.activeAdditionals}))
                    }} >{unit.title}</div>
                    <div className="dish-info">
                        <DishInfoItem measure={unit.measure} type="time" image={timeIcon} quantity={unit.servingTime} />
                        <DishInfoItem measure={unit.measure} type="weight" image={weightIcon} quantity={unit.quantity} />
                    </div>
                    <p className="dish-option">{unit.activeOption ? unit.activeOption.title : ''}{unit.activeAdditionals && unit.activeAdditionals.length > 0 ?  unit.activeOption ? ', ' + unit.activeAdditionals?.map(item => item.title).join(', ') : unit.activeAdditionals?.map(item => item.title).join(', ')  : ''}</p>
                    <DishButtons price={unit.price} unit={unit} type="addedProduct" />
                </div>
            </div>
            <div className="added-product__finish">
                <div onClick={removeItem} className="added-product__close">
                    {colorScheme === "dark" ?
                        <img src={closeWhiteIcon} alt="" /> :
                        <img src={closeIcon} alt="" />
                    }

                </div>
                <div className="added-product__price">
                    {/* @ts-ignore */}
                    {
                        !unit.oldPrice || unit.oldPrice === 0  ?
                        '' : <p className={'old-price'}>{unit.oldPrice} ₽</p>
                    }
                    <p>{unit.price} ₽</p>
                </div>
            </div>
        </div>
    )
}

export default AddedProduct;
