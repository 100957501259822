import React from "react";
import {useMantineColorScheme} from "@mantine/core";

type ProductMeasuresItemProps = {
    name: string,
    value: string | number
}

const ProductMeasuresItem: React.FC<ProductMeasuresItemProps> = ({name, value}) => {

    const { colorScheme } = useMantineColorScheme();

    return (
        <div className={`product-measures__item ${colorScheme === "dark" ? "dark" : ""}`}>
            <div className="product-measures__item-value">{value}</div>
            <div className="product-measures__item-name">{name}</div>
        </div>
    )
}

export default ProductMeasuresItem;
