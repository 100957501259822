import React, { useEffect, useState } from "react";
import favorite from "../../../assets/images/favorite-white.svg";
import favoriteActive from "../../../assets/images/favorite_active.svg";
import timeIcon from "../../../assets/images/time.svg";
import weightIcon from "../../../assets/images/weight.svg";
import productImage from "../../../assets/images/product_image.png";
import { IMenuItem } from "../../../types";
import DishInfoItem from "../dish/DishInfoItem";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { setShowProductModal, setProductForModal } from "../../../redux/slices/main";
import DishButtons from "../dish/DishButtons";
import { addToCart, addToFavorite, removeFromFavorite } from "../../../redux/slices/menu";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type CartProps = {
    unit: IMenuItem
}

const Card: React.FC<CartProps> = ({ unit }) => {
    const dispatch = useAppDispatch();
    const favoriteItems = useSelector((state: RootState) => state.menu.favoriteItems);

    const setFavoriteValueHandler = () => {
        if (!favoriteItems.some(item => item._id === unit._id)) {
            dispatch(addToFavorite(unit));
        } else {
            dispatch(removeFromFavorite(unit));
        }
    }

    const showProductModalHandler = (value: boolean, unit: IMenuItem) => {
        dispatch(setProductForModal(unit))
        dispatch(setShowProductModal(value));
    }

    return (
        <div className="card">
            <div className="card-image__favorite" onClick={setFavoriteValueHandler}>
                <img src={favoriteItems.some(item => item._id === unit._id) ? favoriteActive : favorite} alt="" />
            </div>
            <div style={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(https://s3.vkusclick.ru/${unit.image})` }} className="card-image__wrapper" onClick={() => showProductModalHandler(true, unit)}>
                {/*<img className="card-image" src={unit.image !== '' ? `https://s3.vkusclick.ru/${unit.image}` : productImage} alt="" />*/}
            </div>
            <div className="card-name">{unit.title}</div>
            <div className="dish-info">
                <DishInfoItem measure={unit.measure} type="time" image={timeIcon} quantity={unit.servingTime} />
                <DishInfoItem measure={unit.measure} type="weight" image={weightIcon} quantity={unit.quantity} />
            </div>
            <DishButtons onClick={() => showProductModalHandler(true, unit)} price={unit.price} unit={unit} />
        </div>
    )
}

export default Card;
