import React, { useEffect, useState } from 'react';
import { IMenuItem } from "../../../types";
import productImage from '../../../assets/images/product_image.png';
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useDispatch } from "react-redux";
import { addCountProductCart, addToCart, removeCountFromCart, removeFromCart, removeFromFavorite } from "../../../redux/slices/menu";
import favorite from "../../../assets/images/favorite_active.svg";
import plusWhiteForAddedProductIcon from "../../../assets/images/plus_white_for_added_product.svg";
import plusForAddedProductIcon from "../../../assets/images/plus_for_added_product.svg";
import { useMantineColorScheme } from "@mantine/core";
import minusWhiteForAddedProductIcon from "../../../assets/images/minus_white_for_added_product.svg";
import minusForAddedProductIcon from "../../../assets/images/minus_for_added_product.svg";
import { setProductForModal, setShowFavorite, setShowProductModal } from '../../../redux/slices/main';

function FavoriteItem({ unit }: { unit: IMenuItem }) {

    const dispatch = useDispatch();
    const [count, setCount] = useState(0);
    const cartItems = useAppSelector(state => state.menu.cartItems);
    const { colorScheme } = useMantineColorScheme();

    useEffect(() => {

        let count = 0;
        cartItems.map(item => {
            if (item._id === unit._id) {
                count += item.count
            }
        })
        setCount(count);

    }, [cartItems])

    const chowProduct = () => {
        dispatch(setProductForModal(unit))
        dispatch(setShowProductModal(true));
    }

    return (
        <div className={'favorite-item'}>
            <button onClick={() => dispatch(removeFromFavorite(unit))} className={'favorite-button'}>
                <img src={favorite} alt="" />
            </button>
            <div className="img-wrap" style={{ background: `url(https://s3.vkusclick.ru/${unit.image})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
            </div>
            <div className="favorite-item--description">
                <p className="item-title">{unit.title}</p>
                <div className="description-info">
                    <p className="info-time">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M6.00001 1.2002C3.34876 1.2002 1.20001 3.34895 1.20001 6.0002C1.20001 8.65145 3.34876 10.8002 6.00001 10.8002C8.65126 10.8002 10.8 8.65145 10.8 6.0002C10.8 3.34895 8.65126 1.2002 6.00001 1.2002ZM6.00001 9.99957C3.79126 9.99957 2.00064 8.20895 2.00064 6.0002C2.00064 3.79145 3.79126 2.00082 6.00001 2.00082C8.20876 2.00082 9.99939 3.79145 9.99939 6.0002C9.99939 8.20895 8.20876 9.99957 6.00001 9.99957ZM6.39939 2.79957H5.59876V6.0002L7.39876 7.8002L7.99876 7.2002L6.39939 5.60082V2.79957Z" fill="#C24624" />
                        </svg>
                        {unit.servingTime} мин
                    </p>
                    <p className="info-weight">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M5.19759 2.97H5.88208C6.08743 2.97 6.22433 2.826 6.22433 2.61C6.22433 2.394 6.08743 2.25 5.88208 2.25H5.19759C4.99225 2.25 4.85535 2.394 4.85535 2.61C4.85535 2.826 4.99225 2.97 5.19759 2.97Z" fill="#C24624" />
                            <path d="M10.297 7.46971C10.2286 7.36171 10.0917 7.28971 9.98902 7.28971H9.61255C9.44142 5.70571 8.20934 4.12171 6.63501 3.61771C5.91629 3.36571 5.12912 3.36571 4.41041 3.61771C2.8703 4.12171 1.60399 5.70571 1.46709 7.28971H1.09062C0.987947 7.28971 0.851048 7.36171 0.782599 7.46971C0.71415 7.57771 0.71415 7.72171 0.782599 7.82971C1.26174 8.83771 2.22003 9.44971 3.31522 9.44971H7.79864C8.8596 9.44971 9.85212 8.83771 10.3313 7.79371C10.3313 7.68571 10.3313 7.57771 10.297 7.46971ZM4.61575 4.30171C5.19757 4.12171 5.84784 4.12171 6.42966 4.30171C7.69597 4.69771 8.75693 5.99371 8.92805 7.28971H2.15158C2.32271 5.99371 3.34944 4.73371 4.61575 4.30171ZM7.76442 8.72971H3.31522C2.69918 8.72971 2.15158 8.47771 1.74089 8.00971H9.33875C8.92805 8.47771 8.38046 8.72971 7.76442 8.72971Z" fill="#C24624" />
                        </svg>
                        {`${unit.quantity} ${unit.measure === 'grams' ? "г" : unit.measure === 'kilograms' ? 'кг' : unit.measure === 'milliliters' ? 'мл' : unit.measure === 'liters' ? 'л' : unit.measure === 'pieces' ? 'шт' : 'шт'}`}
                    </p>
                </div>
                {count === 0 ?
                    <button onClick={() => unit.optionsItems ? chowProduct() : dispatch(addToCart({ ...unit, count: 1 }))} className="item-button--price">{unit.price} ₽</button>
                    :
                    <div className={'item-button--alreadyInCart'}>
                        <button onClick={() => {
                            const unitPrice = cartItems.find(item => item._id === unit._id)?.price
                            const unitCount = cartItems.find(item => item._id === unit._id)?.count
                            dispatch(removeCountFromCart({...unit, from: 'menu', count: unitCount, price: unitCount && unitPrice ? unitPrice : unit.price}))
                            setCount(count - 1)
                        }} className="item-button--minus"><img src={colorScheme === "dark" ? minusWhiteForAddedProductIcon : minusForAddedProductIcon} alt="" /></button>
                        <p className="item-count">{count}</p>
                        <button onClick={() => unit.optionsItems ? chowProduct() : dispatch(addCountProductCart({ ...unit, count: 1 }))} className="item-button--minus"><img src={colorScheme === "dark" ? plusWhiteForAddedProductIcon : plusForAddedProductIcon} alt="" /> </button>
                    </div>

                }
            </div>
        </div>

    );
}

export default FavoriteItem;
