import React from "react";

const Footer: React.FC = () => {
    return (
        <div className="container" style={{marginTop: 'auto', display: 'block'}}>
            <div className="footer">
                <div className="footer-start">
                    © 2023 e-menu
                </div>
            </div>
        </div>
    )
}

export default Footer
