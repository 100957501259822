import { useState } from "react";

function DropDown({ q, a, link }) {

    const [active, setActive] = useState(false)

    return (
        <div className={`block advantages__block ${active ? 'active' : ''}`}>
            <p className="question" onClick={() => setActive(!active)}>
                {q}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M20.8682 16.8769C20.6925 17.041 20.4075 17.041 20.2318 16.8769L12 9.18874L3.7682 16.8769C3.59246 17.041 3.30754 17.041 3.1318 16.8769C2.95607 16.7128 2.95607 16.4467 3.1318 16.2825L12 8L20.8682 16.2825C21.0439 16.4467 21.0439 16.7128 20.8682 16.8769Z"
                        fill="#090909" />
                </svg>
            </p>
            <p className="answer">{a}
                <>
                    {
                        link &&
                        <a href="https://t.me/vkusclick_officiant_bot"> {link}</a>
                    }
                </>
            </p>
        </div>
    );
}

export default DropDown;