import { useMantineColorScheme } from "@mantine/core";
import React, {useEffect, useState} from "react";
import wifiChapter from "../../../assets/images/wifi_chapter.svg";
import aboutChapter from "../../../assets/images/about_chapter.svg";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {useDispatch} from "react-redux";
import {setActiveCategory, setCategories} from "../../../redux/slices/menu";
import {setShowInfoModal, setShowWifiModal} from "../../../redux/slices/main";

const HomeChapters: React.FC = () => {
  const { colorScheme } = useMantineColorScheme();
  const {restaurantInfo} = useAppSelector(state => state.main);
  const {categories, activeCategory, products} = useAppSelector(state => state.menu);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cards, setCards] = useState<any>();

  useEffect(() => {
    if(restaurantInfo?.mainPageMenuCategoriesId && restaurantInfo?.mainPageMenuCategoriesId.length > 0) {
      setCards(restaurantInfo?.mainPageMenuCategoriesId.map(id => {
        if (products?.map(item => item.categoryId).includes(id) && categories?.map(item => item._id).includes(id)) {
          return id
        }
      }).filter(item => item));
    }
  }, [restaurantInfo, products, categories])

  return (
        <div className="container">
            <div className="home-chapters">
              {
                restaurantInfo?.mainPageMenuImage &&
                <NavLink onClick={() => dispatch(setActiveCategory({...activeCategory, linked: false}))} style={{backgroundPosition: 'center',backgroundSize: 'cover',backgroundImage: `url(${restaurantInfo?.mainPageMenuImage})`}} to={`/${params.code}/${params.table}/menu`} className="home-chapters__item home-chapters__menu">
                  <h3 className="home-chapters__item-text">Меню</h3>
                </NavLink>
              }
                {
                  // @ts-ignore
                 cards?.map((item: string, i) => {
                    if(i < 2) {
                      return (
                        //@ts-ignore
                        <div style={{backgroundPosition: 'center',backgroundSize: 'cover',backgroundImage: `url(${categories?.find(category => category._id === item) && categories?.find(category => category._id === item).image})`}} key={i} className="home-chapters__item" onClick={() => {
                            /* @ts-ignore */
                            dispatch(setActiveCategory({...categories?.find(category => category._id === item) && categories?.find(category => category._id === item), linked: true}))
                            navigate(`/${params.code}/${params.table}/menu`)
                        } 
                        }>
                          {/* @ts-ignore */}
                          <h3 className="home-chapters__item-text">{categories?.find(category => category._id === item) && categories?.find(category => category._id === item).title}</h3>
                          {/* @ts-ignore */}
                          {/*<img src={categories?.find(category => category._id === item) && categories?.find(category => category._id === item).image} alt=""/>*/}
                        </div>
                      )
                    }
                  })
                }
                {restaurantInfo?.wiFiSSID &&
                  <div onClick={() => dispatch(setShowWifiModal(true))} className={`home-chapters__empty ${colorScheme === "dark" && "dark"} home-chapters__wifi`}>
                    <div className="home-chapters__empty-img">
                      <img src={wifiChapter} alt=""/>
                    </div>
                    <div className="home-chapters__empty-text">WiFi</div>
                  </div>
                }
                {
                  restaurantInfo?.description &&
                  <div onClick={() => dispatch(setShowInfoModal(true))} className={`home-chapters__empty ${colorScheme === "dark" && "dark"} home-chapters__about`}>
                    <div className="home-chapters__empty-img">
                      <img src={aboutChapter} alt=""/>
                    </div>
                    <div className="home-chapters__empty-text">О ресторане</div>
                  </div>
                }
            </div>
        </div>

    )
}

export default HomeChapters;
