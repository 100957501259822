import { Switch, useMantineColorScheme, createStyles } from '@mantine/core';
import IconSun from "../../assets/images/sun.svg";
import IconMoonStars from "../../assets/images/moon.svg";

const useStyles = createStyles((theme) => ({
    track: {
        // backgroundColor: theme.colorScheme === 'dark' ? theme.black : theme.white ,
        // borderColor: theme.colorScheme === 'dark' ? theme.white : theme.black,
        width: '48px',
        height: '26px',
        cursor: 'pointer'
    },
    thumb: {
        width: '20px',
        height: '20px',
        marginLeft: theme.colorScheme === 'dark' ? '0px' : '-6px'
    },
    trackLabel: {
        marginLeft: theme.colorScheme === 'dark' ? '25px' : '0px'
    }
}));

const SwitchToggle = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const { classes } = useStyles();

  return (
        <Switch
            checked={colorScheme === 'light'}
            onChange={() => toggleColorScheme()}
            onLabel={<img src={IconSun} alt="" />}
            offLabel={<img src={IconMoonStars} alt="" />}
            classNames={{
                track: classes.track,
                thumb: classes.thumb,
                trackLabel: classes.trackLabel
            }} 
        />
    );
}

export default SwitchToggle;