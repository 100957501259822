import React from 'react';
import img from '../../../assets/images/error-person.svg';
import {useMantineColorScheme} from "@mantine/core";

function Index() {

  const { colorScheme } = useMantineColorScheme();

  return (
    <div className={`error-page ${colorScheme}`}>
      <div className="container">
        <svg width="100" height="98" viewBox="0 0 100 98" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32 26C32.7041 26.7101 32.7037 26.7106 32.7037 26.7106L32.7054 26.7089L32.7237 26.6914C32.7414 26.6745 32.7702 26.6476 32.8094 26.612C32.8879 26.5408 33.0081 26.4354 33.1659 26.307C33.4823 26.0497 33.9455 25.7038 34.5232 25.3581C35.6895 24.6601 37.2596 24 39 24C40.7405 24 42.3105 24.6601 43.4768 25.3581C44.0546 25.7038 44.5177 26.0497 44.8341 26.307C44.9919 26.4354 45.1121 26.5408 45.1907 26.612C45.2299 26.6476 45.2586 26.6745 45.2764 26.6914L45.2946 26.7089L45.2964 26.7106C45.2964 26.7106 45.296 26.7101 46 26C46.7041 25.2899 46.703 25.2888 46.703 25.2888L46.7017 25.2875L46.6982 25.284L46.6878 25.2739L46.6538 25.2413C46.6252 25.2142 46.5851 25.1766 46.5338 25.1302C46.4314 25.0373 46.2841 24.9084 46.0962 24.7555C45.721 24.4503 45.1793 24.0462 44.5038 23.6419C43.1637 22.8399 41.2337 22 39 22C36.7664 22 34.8364 22.8399 33.4962 23.6419C32.8207 24.0462 32.279 24.4503 31.9039 24.7555C31.7159 24.9084 31.5687 25.0373 31.4662 25.1302C31.4149 25.1766 31.3748 25.2142 31.3463 25.2413L31.3122 25.2739L31.3019 25.284L31.2983 25.2875L31.2964 25.2894C31.2964 25.2894 31.296 25.2899 32 26Z" fill="#8692D0"/>
          <path d="M32 26C32.7041 26.7101 32.7037 26.7106 32.7037 26.7106L32.7054 26.7089L32.7237 26.6914C32.7414 26.6745 32.7702 26.6476 32.8094 26.612C32.8879 26.5408 33.0081 26.4354 33.1659 26.307C33.4823 26.0497 33.9455 25.7038 34.5232 25.3581C35.6895 24.6601 37.2596 24 39 24C40.7405 24 42.3105 24.6601 43.4768 25.3581C44.0546 25.7038 44.5177 26.0497 44.8341 26.307C44.9919 26.4354 45.1121 26.5408 45.1907 26.612C45.2299 26.6476 45.2586 26.6745 45.2764 26.6914L45.2946 26.7089L45.2964 26.7106C45.2964 26.7106 45.296 26.7101 46 26C46.7041 25.2899 46.703 25.2888 46.703 25.2888L46.7017 25.2875L46.6982 25.284L46.6878 25.2739L46.6538 25.2413C46.6252 25.2142 46.5851 25.1766 46.5338 25.1302C46.4314 25.0373 46.2841 24.9084 46.0962 24.7555C45.721 24.4503 45.1793 24.0462 44.5038 23.6419C43.1637 22.8399 41.2337 22 39 22C36.7664 22 34.8364 22.8399 33.4962 23.6419C32.8207 24.0462 32.279 24.4503 31.9039 24.7555C31.7159 24.9084 31.5687 25.0373 31.4662 25.1302C31.4149 25.1766 31.3748 25.2142 31.3463 25.2413L31.3122 25.2739L31.3019 25.284L31.2983 25.2875L31.2964 25.2894C31.2964 25.2894 31.296 25.2899 32 26Z" fill="white" fillOpacity="0.8"/>
          <path d="M54.7036 26.7106C54.7036 26.7106 54.704 26.7101 54 26C53.2959 25.2899 53.2964 25.2894 53.2964 25.2894L53.2983 25.2875L53.3018 25.284L53.3122 25.2739L53.3462 25.2413C53.3747 25.2142 53.4149 25.1766 53.4662 25.1302C53.5686 25.0373 53.7159 24.9084 53.9038 24.7555C54.279 24.4503 54.8206 24.0462 55.4961 23.6419C56.8363 22.8399 58.7663 22 61 22C63.2336 22 65.1636 22.8399 66.5038 23.6419C67.1793 24.0462 67.721 24.4503 68.0961 24.7555C68.284 24.9084 68.4313 25.0373 68.5338 25.1302C68.585 25.1766 68.6252 25.2142 68.6537 25.2413L68.6877 25.2739L68.6981 25.284L68.7016 25.2875L68.703 25.2888C68.703 25.2888 68.704 25.2899 68 26C67.2959 26.7101 67.2963 26.7106 67.2963 26.7106L67.2946 26.7089L67.2763 26.6914C67.2586 26.6745 67.2298 26.6476 67.1906 26.612C67.1121 26.5408 66.9919 26.4354 66.8341 26.307C66.5177 26.0497 66.0545 25.7038 65.4767 25.3581C64.3104 24.6601 62.7404 24 61 24C59.2595 24 57.6895 24.6601 56.5232 25.3581C55.9454 25.7038 55.4823 26.0497 55.1659 26.307C55.008 26.4354 54.8879 26.5408 54.8093 26.612C54.7701 26.6476 54.7414 26.6745 54.7236 26.6914L54.7053 26.7089L54.7036 26.7106Z" fill="#8692D0"/>
          <path d="M54.7036 26.7106C54.7036 26.7106 54.704 26.7101 54 26C53.2959 25.2899 53.2964 25.2894 53.2964 25.2894L53.2983 25.2875L53.3018 25.284L53.3122 25.2739L53.3462 25.2413C53.3747 25.2142 53.4149 25.1766 53.4662 25.1302C53.5686 25.0373 53.7159 24.9084 53.9038 24.7555C54.279 24.4503 54.8206 24.0462 55.4961 23.6419C56.8363 22.8399 58.7663 22 61 22C63.2336 22 65.1636 22.8399 66.5038 23.6419C67.1793 24.0462 67.721 24.4503 68.0961 24.7555C68.284 24.9084 68.4313 25.0373 68.5338 25.1302C68.585 25.1766 68.6252 25.2142 68.6537 25.2413L68.6877 25.2739L68.6981 25.284L68.7016 25.2875L68.703 25.2888C68.703 25.2888 68.704 25.2899 68 26C67.2959 26.7101 67.2963 26.7106 67.2963 26.7106L67.2946 26.7089L67.2763 26.6914C67.2586 26.6745 67.2298 26.6476 67.1906 26.612C67.1121 26.5408 66.9919 26.4354 66.8341 26.307C66.5177 26.0497 66.0545 25.7038 65.4767 25.3581C64.3104 24.6601 62.7404 24 61 24C59.2595 24 57.6895 24.6601 56.5232 25.3581C55.9454 25.7038 55.4823 26.0497 55.1659 26.307C55.008 26.4354 54.8879 26.5408 54.8093 26.612C54.7701 26.6476 54.7414 26.6745 54.7236 26.6914L54.7053 26.7089L54.7036 26.7106Z" fill="white" fillOpacity="0.8"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M44 34.5C44 36.433 42.6569 38 41 38C39.3431 38 38 36.433 38 34.5C38 32.567 39.3431 31 41 31C42.6569 31 44 32.567 44 34.5ZM40 35C40 35.5523 40.4477 36 41 36C41.5523 36 42 35.5523 42 35C42 34.4477 41.5523 34 41 34C40.4477 34 40 34.4477 40 35Z" fill="#8692D0"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M44 34.5C44 36.433 42.6569 38 41 38C39.3431 38 38 36.433 38 34.5C38 32.567 39.3431 31 41 31C42.6569 31 44 32.567 44 34.5ZM40 35C40 35.5523 40.4477 36 41 36C41.5523 36 42 35.5523 42 35C42 34.4477 41.5523 34 41 34C40.4477 34 40 34.4477 40 35Z" fill="white" fillOpacity="0.8"/>
          <path d="M50 51C51.6569 51 53 49.6569 53 48C53 46.3431 51.6569 45 50 45C48.3431 45 47 46.3431 47 48C47 49.6569 48.3431 51 50 51Z" fill="#8692D0"/>
          <path d="M50 51C51.6569 51 53 49.6569 53 48C53 46.3431 51.6569 45 50 45C48.3431 45 47 46.3431 47 48C47 49.6569 48.3431 51 50 51Z" fill="white" fillOpacity="0.8"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M59 38C60.6569 38 62 36.433 62 34.5C62 32.567 60.6569 31 59 31C57.3431 31 56 32.567 56 34.5C56 36.433 57.3431 38 59 38ZM59 36C58.4477 36 58 35.5523 58 35C58 34.4477 58.4477 34 59 34C59.5523 34 60 34.4477 60 35C60 35.5523 59.5523 36 59 36Z" fill="#8692D0"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M59 38C60.6569 38 62 36.433 62 34.5C62 32.567 60.6569 31 59 31C57.3431 31 56 32.567 56 34.5C56 36.433 57.3431 38 59 38ZM59 36C58.4477 36 58 35.5523 58 35C58 34.4477 58.4477 34 59 34C59.5523 34 60 34.4477 60 35C60 35.5523 59.5523 36 59 36Z" fill="white" fillOpacity="0.8"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M73.4158 53.8107C78.7416 48.0955 82 40.4283 82 32C82 14.3269 67.6731 0 50 0C32.3269 0 18 14.3269 18 32C18 40.5604 21.3614 48.3357 26.8362 54.0781C26.7517 53.9894 26.6677 53.9003 26.5842 53.8107C10.7698 62.2081 0 78.8463 0 98H4C4 79.8898 14.4656 64.224 29.6788 56.7206C29.6787 56.7206 29.6788 56.7206 29.6788 56.7206C35.2053 61.2689 42.2837 64 50 64C57.732 64 64.8235 61.2577 70.355 56.6928C70.3437 56.702 70.3325 56.7113 70.3212 56.7206C85.5344 64.224 96 79.8898 96 98H100C100 78.8463 89.2301 62.2081 73.4158 53.8107ZM50 60C65.464 60 78 47.464 78 32C78 16.536 65.464 4 50 4C34.536 4 22 16.536 22 32C22 47.464 34.536 60 50 60Z" fill="#8692D0"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M73.4158 53.8107C78.7416 48.0955 82 40.4283 82 32C82 14.3269 67.6731 0 50 0C32.3269 0 18 14.3269 18 32C18 40.5604 21.3614 48.3357 26.8362 54.0781C26.7517 53.9894 26.6677 53.9003 26.5842 53.8107C10.7698 62.2081 0 78.8463 0 98H4C4 79.8898 14.4656 64.224 29.6788 56.7206C29.6787 56.7206 29.6788 56.7206 29.6788 56.7206C35.2053 61.2689 42.2837 64 50 64C57.732 64 64.8235 61.2577 70.355 56.6928C70.3437 56.702 70.3325 56.7113 70.3212 56.7206C85.5344 64.224 96 79.8898 96 98H100C100 78.8463 89.2301 62.2081 73.4158 53.8107ZM50 60C65.464 60 78 47.464 78 32C78 16.536 65.464 4 50 4C34.536 4 22 16.536 22 32C22 47.464 34.536 60 50 60Z" fill="white" fillOpacity="0.8"/>
        </svg>

        <h3 style={{fontSize: '15px', maxWidth: '670px', margin: '0 auto'}}>Для работы меню заполните раздел настроек заведения, создайте категорию, блюдо и назначьте хотя бы одного официанта.</h3>
      </div>
    </div>
  );
}

export default Index;
