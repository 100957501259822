import { configureStore } from "@reduxjs/toolkit";
import menuReducer from "./slices/menu";
import mainReducer from "./slices/main";

const reducer = {
    menu: menuReducer,
    main: mainReducer,
}

export const store = configureStore({
    reducer
})
  
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;