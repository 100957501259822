import MenuCategorySection from "../../pages/menu/MenuCategorySection";
import {useAppSelector} from "../../../hooks/useAppSelector";

function SearchContent() {

    const items = useAppSelector(state => state.menu.products);
    const categories = useAppSelector(state => state.menu.categories);
    const searchValue = useAppSelector(state => state.menu.searchValue);

    return (
        <div className={'search-content'}>
            {
                searchValue.trim() !== '' ?
                <>
                    <h1 className="search-content--title">Найдено</h1>
                    <MenuCategorySection title={''} items={items?.filter(item => item.title.toLowerCase().includes(searchValue.toLowerCase()) && categories?.map(id => id._id).includes(item.categoryId))}/>
                </> :
                <>
                    <h1 className="search-content--title">Ничего не найдено</h1>
                </>
            }
            
        </div>
    );
}

export default SearchContent;
