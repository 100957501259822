import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route, Navigate, useLocation, useParams, useNavigate} from 'react-router-dom';
import { ColorSchemeProvider, MantineProvider, ColorScheme } from '@mantine/core';
import HomePage from './components/pages/home';
import MenuPage from './components/pages/menu';
import OrderPage from './components/pages/order';
import CartBtn from "./components/common/buttons/CartBtn";
import {useAppSelector} from "./hooks/useAppSelector";
import Search from "./components/common/modals/search";
import {useDispatch} from "react-redux";
import {setShowHeader} from "./redux/slices/main";
import {distance} from "framer-motion";
import {setCart, setCartLength, setCartTotalPrice, setFavorite} from "./redux/slices/menu";
import Error from "./components/pages/error";
import Blocked from './components/pages/BlockError';
import CategoriesError from "./components/pages/CategoriesError";
import { YMInitializer } from 'react-yandex-metrika';
import Landing from './components/pages/landing';

const App: React.FC = () => {
    const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
    const {restaurantInfo} = useAppSelector(state => state.main);
    const {categoriesError} = useAppSelector(state => state.menu);
    const dispatch = useDispatch();

    const toggleColorScheme = (value?: ColorScheme) =>
      setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    useEffect(() => {
        const theme = window.localStorage.getItem('theme');
        if(theme && window.location.pathname !== '/') {
            setColorScheme(theme === 'light' ? 'light' : 'dark');
        } else {
          if(restaurantInfo && window.location.pathname !== '/') {
            setColorScheme(restaurantInfo.defaultTheme === 'light' ? 'light' : 'dark')
            localStorage.setItem('theme', restaurantInfo.defaultTheme === 'light' ? 'light' : 'dark');
          }
        }
    }, [restaurantInfo])

    const params = useParams();

    useEffect(() => {
      if(window.location.pathname.length < 8 && window.location.pathname !== '/error' && window.location.pathname !== '/') {
        window.location.href = '/error'
      }
      if(window.localStorage.getItem('theme') && window.localStorage.getItem('theme') !== colorScheme && window.location.pathname !== '/') {
        localStorage.setItem('theme', colorScheme);
      }
    }, [colorScheme])

    useEffect(() => {
      const items = localStorage.getItem('favoriteItems')
      if(items) {
        dispatch(setFavorite(JSON.parse(items)))
      }

      const cart = localStorage.getItem('cartItems');
      if(cart) {
        dispatch(setCart(JSON.parse(cart)));
      }

      const cartLength = localStorage.getItem('cartLength');
      if(cartLength) {
        dispatch(setCartLength(cartLength));
      }

      const cartTotalPrice = localStorage.getItem('cartTotalPrice');
      if(cartTotalPrice) {
        dispatch(setCartTotalPrice(cartTotalPrice));
      }
    }, [])

    return (
      <>
        <YMInitializer accounts={[94417584]} options={{webvisor: true, defer: true}} version="2"/>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
                theme={{
                    colorScheme,
                    white: "#F8F9FA",
                    black: "#101113",
                    fontFamily: 'Montserrat, sans-serif !important',
                }}
                withGlobalStyles withNormalizeCSS
            >
                {!categoriesError ?
                  <BrowserRouter>
                    <Routes>
                      <Route path='/' element={<Landing setTheme={() => setColorScheme('light')}/>}/>
                      <Route path='/:code/:table' element={<HomePage/>}/>
                      <Route path='/:code/:table/menu' element={<MenuPage/>}/>
                      <Route path='/:code/:table/order' element={<OrderPage/>}/>
                      <Route path='/error' element={<Error/>}/>
                      <Route path='/blocked' element={<Blocked/>}/>
                    </Routes>
                  </BrowserRouter> :
                  <CategoriesError/>}
            </MantineProvider>

        </ColorSchemeProvider>
      </>
    );
}

export default App;
