import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { IMenuItem } from "../../../types";
import MenuCategorySection from "./MenuCategorySection";
import {getCategories, getDishes, getPlaceInfo, getPopularId} from "../../../hooks/getPlaceInfo";
import {setRestaurantInfo} from "../../../redux/slices/main";
import {setActiveCategory, setCategories, setPopularProductsId, setProducts} from "../../../redux/slices/menu";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

const MenuCategory: React.FC = () => {
    const {products, categories, activeCategory, popularProductsId} = useAppSelector(state => state.menu);
    const {restaurantInfo} = useAppSelector(state => state.main);
    const [menuItemsLocal, setMenuItemsLocal] = useState<IMenuItem[] | []>([]);
    const [menuItemsLocalWithoutSubCategory, setMenuItemsLocalWithoutSubCategory] = useState<IMenuItem[] | []>([]);
    const [subCategoriesLocal, setSubCategoriesLocal] = useState<string[] | []>([]);
    // const params = useParams();
    // const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
      if(products && categories && activeCategory) {
        if(activeCategory.title === 'Популярные' && popularProductsId && popularProductsId.length > 0) {
          if(popularProductsId) {
            setSubCategoriesLocal([...popularProductsId])
          }
        } else {
          // @ts-ignore
          setSubCategoriesLocal([...products.filter(item => item.categoryId === activeCategory._id).map(item => item._id)])
        }
      }
    }, [products, categories, activeCategory, popularProductsId])

  useEffect(() => {
    if(!activeCategory) {
      if(subCategoriesLocal.length === 0) {
        if(categories?.filter(item => item.active) && categories?.filter(item => item.active).length > 0) {
          if(popularProductsId?.length && popularProductsId?.length > 0 && products?.filter(item => popularProductsId.includes(item._id) && item.image !== '').length && products?.filter(item => popularProductsId.includes(item._id) && item.image !== '').length > 0) {
            dispatch(setActiveCategory({title: 'Популярные', active: true}))
          } else {
            dispatch(setActiveCategory(categories?.filter(item => item.active && item.title !== "Популярные")[0]))
          }
        }
      }
    }
  }, [subCategoriesLocal, popularProductsId, products, categories])

  return (
        <div className="menu-category">
            {menuItemsLocalWithoutSubCategory.length ?
                <MenuCategorySection title={categories && activeCategory ? activeCategory.title : ''} items={menuItemsLocalWithoutSubCategory} /> : ""
            }
          <MenuCategorySection
                    title={activeCategory ? activeCategory.title : ''}
                    // @ts-ignore
                    items={products?.filter(item => subCategoriesLocal.includes(item._id))}
                />
        </div>
    )
}

export default MenuCategory;
