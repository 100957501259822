import axios from "axios";

export const getPlaceInfo = async (code: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/code/${code}`);
    return result.data[0]
  } catch (e) {

  }
}

export const getCategories = async (id: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/categories`);
    return [...result.data]
  } catch (e) {

  }
}


export const getDishes = async (id: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/dishes`);
    return [...result.data]
  } catch (e) {

  }
}

export const getPopularId = async (id: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/popular_dishes`);
    return [...result.data[0].dishesId]
  } catch (e) {

  }
}

export const getAddToOrder = async (id: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/add_to_order_dishes`);
    return [...result.data[0].dishesId]
  } catch (e) {

  }
}
