import React, {useEffect, useState} from "react";
import MainLayout from "../../layouts/MainLayout";
import OrderStart from "./OrderStart";
import OrderSuccessful from "./OrderSuccessful";
import {useDispatch} from "react-redux";
import {setRestaurantInfo, setShowHeader} from "../../../redux/slices/main";
import {useMantineColorScheme} from "@mantine/core";
import {getCategories, getDishes, getPlaceInfo, getPopularId} from "../../../hooks/getPlaceInfo";
import {setCategories, setPopularProductsId, setProducts} from "../../../redux/slices/menu";
import {useNavigate, useParams} from "react-router-dom";
import ym from 'react-yandex-metrika';

const OrderPage: React.FC = () => {
    const [succesfulOrder, setSuccesfulOrder] = useState<boolean>(false);
    const { colorScheme } = useMantineColorScheme();

    const setSuccesfulOrderHandler = () => {
        setSuccesfulOrder(true);
    }

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

  const start = () => {
    if(params.code && params.table){
      if(params.code !== localStorage.getItem('code')) {
        localStorage.removeItem('cartItems');
        localStorage.removeItem('cartLength');
        localStorage.removeItem('cartTotalPrice');
        localStorage.removeItem('favoriteItems');
        localStorage.setItem('code', params.code);
      }
      getPlaceInfo(params.code).then(response => {
        if(response) {
          dispatch(setRestaurantInfo({...response}))
          getDishes(response._id).then(products => {
            if(products) {
              dispatch(setProducts([...products]))
            }
            getCategories(response._id).then(categories => {
              if(categories) {
                const arr = (products?.map(product => {
                  if (categories.map(item => item._id).includes(product.categoryId) && product.active && product.image !== '') {
                    if (categories.filter(item => item._id === product.categoryId)[0]._id !== undefined) {
                      return (categories.filter(item => item._id === product.categoryId)[0]._id);
                    }
                  }
                }).filter(item => item));
                dispatch(setCategories([...categories.filter(item => arr?.includes(item._id))]))
              }
            })
          })
          getPopularId(response._id).then(id => {
            if(id) {
              dispatch(setPopularProductsId([...id]))
            }
          })
        } else {
          navigate('/error')
        }
      });
    } else {
      navigate('/error')
    }
  }

    useEffect(() => {
        start();
        dispatch(setShowHeader(true));
        if(params.code && params.table){
          ym('hit', `/${params.code}/${params.table}/order`);
        }
    }, [])

    return (
        <MainLayout>
            <div className={`order ${colorScheme === 'dark' ? 'dark' : ''}`}>
                {succesfulOrder ?
                    <OrderSuccessful /> :
                    <OrderStart setSuccesfulOrderHandler={setSuccesfulOrderHandler} />
                }
            </div>
        </MainLayout>
    )
}

export default OrderPage;
