import React, {useEffect} from "react";
import MainLayout from "../../layouts/MainLayout";
import HomeChapters from "./HomeChapters";
import ForItemsSlider from "../../common/sliders/ForItemsSlider";
import HomeBase from "./HomeBase";
import CartBtn from "../../common/buttons/CartBtn";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {setRestaurantInfo, setShowHeader} from "../../../redux/slices/main";
import {getCategories, getDishes, getPlaceInfo, getPopularId} from "../../../hooks/getPlaceInfo";
import {setCategories, setPopularProductsId, setProducts} from "../../../redux/slices/menu";
import ym from 'react-yandex-metrika';

const HomePage: React.FC = () => {

  const totalPrice = useAppSelector(state => state.menu.cartTotalPrice);
  const popularProductsId = useAppSelector(state => state.menu.popularProductsId);
  const {restaurantInfo} = useAppSelector(state => state.main);
  const {products} = useAppSelector(state => state.menu);

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const start = () => {
      if(params.code && params.table){
        if(params.code !== localStorage.getItem('code')) {
          localStorage.removeItem('cartItems');
          localStorage.removeItem('cartLength');
          localStorage.removeItem('favoriteItems');
          localStorage.removeItem('cartTotalPrice');
          localStorage.setItem('code', params.code);
        }
        localStorage.setItem('table', params.table);
        getPlaceInfo(params.code).then(response => {
          if(response) {
            if(response.blocked === true){
              navigate('/blocked');
            }
            dispatch(setRestaurantInfo({...response}))
            getDishes(response._id).then(products => {
              if(products) {
                dispatch(setProducts([...products]))
              }
              getCategories(response._id).then(categories => {
                if(categories) {
                  const arr = (products?.map(product => {
                    if (categories.map(item => item._id).includes(product.categoryId) && product.active && product.image !== '') {
                      if (categories.filter(item => item._id === product.categoryId)[0]._id !== undefined) {
                        return (categories.filter(item => item._id === product.categoryId)[0]._id);
                      }
                    }
                  }).filter(item => item));
                  dispatch(setCategories([...categories.filter(item => arr?.includes(item._id))]))
                }
              })
            })
            getPopularId(response._id).then(id => {
              if(id) {
                dispatch(setPopularProductsId([...id]))
              }
            })
          } else {
           navigate('/error')
          }

      });
    } else {
      navigate('/error')
    }
  }

    useEffect(() => {
      start();
      dispatch(setShowHeader(true));
      if(params.code && params.table){
        ym('hit', `/${params.code}/${params.table}`);
      }
    }, [])

    useEffect(() => {
      (products?.map(item => item._id));
      (products?.filter(item => popularProductsId?.includes(item._id)));
    }, [popularProductsId, products])

    return (
        <MainLayout type="home">
            <div className="home">
                <div className="container">
                    <h1 className="title">{restaurantInfo?.title}</h1>
                </div>
                <HomeChapters />
              {
                popularProductsId?.length && popularProductsId?.length > 0 && products?.filter(item => popularProductsId.includes(item._id) && item.image !== '').length && products?.filter(item => popularProductsId.includes(item._id) && item.image !== '').length > 0 ?
                <div className="container">
                  <div className="home-slider">
                    <ForItemsSlider popular={true} title="Популярное" />
                  </div>
                </div> : null
              }
            </div>
            {
                totalPrice > 0 ?
                    <CartBtn text={`В заказ ${totalPrice} ₽`}/>
                    : ''
            }
        </MainLayout>
    )
}

export default HomePage
