import React, {useEffect} from 'react';
import SearchBlock from "../../searchBlock/SearchBlock";
import SearchContent from "../../searchContent/SearchContent";
import MainLayout from "../../../layouts/MainLayout";
import {useDispatch} from "react-redux";
import {setShowHeader} from "../../../../redux/slices/main";
import {useMantineColorScheme} from "@mantine/core";


function Search() {

    const dispatch = useDispatch();
    const { colorScheme } = useMantineColorScheme();

    return (
            <div className={`search-page ${colorScheme === 'dark' ? 'dark' : ''}`}>
                <div className="container">
                    <SearchBlock/>
                    <SearchContent/>
                </div>
            </div>
    );
}

export default Search;